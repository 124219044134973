import Errors from '../../shared/error/errors';
import { getHistory } from '../../store';
import companiesService from '../companiesService';

const prefix = 'COMPANIES_VIEW';

const companiesViewActions = {
    FIND_STARTED: `${prefix}_FIND_STARTED`,
    FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
    FIND_ERROR: `${prefix}_FIND_ERROR`,

    doFind: (id) => async (dispatch) => {
        try {
            dispatch({
                type: companiesViewActions.FIND_STARTED,
            });

            const companies = await companiesService.find(id);

            dispatch({
                type: companiesViewActions.FIND_SUCCESS,
                payload: companies,
            });
        } catch (error) {
            Errors.handle(error);

            dispatch({
                type: companiesViewActions.FIND_ERROR,
            });

            getHistory().push('/companies');
        }
    },
};

export default companiesViewActions;
