import authAxios from 'src/modules/shared/axios/authAxios'

export default class cohortsService{
  static async find(id) {
    const response = await authAxios.get(
      `/student/program/cohort/${id}/students`,
    );
    return response.data;
  }

  static async fetchCohortsCalender(range){
    const response = await authAxios.get(
      `student/program/cohort/calender/${range}`,
    );
    return response.data
  }

  static async fetchCohorts(){
    const response = await authAxios.get(
      `student/program/cohort/`,
    );
    return response.data
  }

  static async fetchOneCohort(cohortId){
    const response = await authAxios.get(
      `student/program/cohort/${cohortId}`,
    );
    return response.data
  }

  static async createCohort(data, id){
    const params = {
      programId: id
    }
    const response = await authAxios.post(
      `student/program/cohort/`,
      data,
      {
        params,
      },
    );
    return response.data
  }

  static async updateCohort(data, id){
    const response = await authAxios.put(
      `student/program/cohort/${id}`,
      data,
    );
    return response.data
  }

  static async deleteCohort(id){
    const response = await authAxios.delete(
      `student/program/cohort/${id}`,
    )
    return response.data
  }
}
