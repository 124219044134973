import actions from 'src/modules/auth/authActions';

const initialData = {
  currentUser: null,
  loadingInit: true,
  loadingPassword: false,
  loadingUpdateProfile: false,
  loading: false,
  errorMessage: null,
  errorMessageVerifyEmail: null,
  photoProfile: null
};

export default (state = initialData, { type, payload }) => {
  if (type === actions.ERROR_MESSAGE_CLEARED) {
    return {
      ...state,
      errorMessage: null,
    };
  }

  if( type === actions.PHOTO_PROFILE) {
    return {
      ...state,
      photoProfile: payload.photo
    }
  }
  
  if (type === actions.CURRENT_USER_REFRESH_SUCCESS) {
    return {
      ...state,
      currentUser: payload.currentUser || null,
    };
  }
  
  if (type === actions.CURRENT_USER_REFRESH_ERROR) {
    return {
      ...state,
      currentUser: null,
    };
  }

  if (type === actions.AUTH_START) {
    return {
      ...state,
      errorMessage: null,
      loading: true,
    };
  }

  if (type === actions.AUTH_SUCCESS) {
    return {
      ...state,
      currentUser: payload.currentUser || null,
      errorMessage: null,
      loading: false,
    };
  }

  if (type === actions.AUTH_ERROR) {
    return {
      ...state,
      currentUser: null,
      errorMessage: payload || null,
      loading: false,
    };
  }

   if (type === actions.UPDATE_PASSWORD_START) {
    return {
      ...state,
      loadingPassword: true,
    };
  }

  if (type === actions.UPDATE_PASSWORD_SUCCESS) {
    return {
      ...state,
      loadingPassword: false,
    };
  }

  if (type === actions.UPDATE_PASSWORD_ERROR) {
    return {
      ...state,
      loadingPassword: false,
    };
  }



  if (type === actions.UPDATE_PROFILE_START) {
    return {
      ...state,
      loadingUpdateProfile: true,
    };
  }

  if (type === actions.UPDATE_PROFILE_SUCCESS) {
    return {
      ...state,
      loadingUpdateProfile: false,
    };
  }

  if (type === actions.UPDATE_PROFILE_ERROR) {
    return {
      ...state,
      loadingUpdateProfile: false,
    };
  }

  if (type === actions.AUTH_INIT_SUCCESS) {
    return {
      ...state,
      currentUser: payload.currentUser || null,
      loadingInit: false,
    };
  }

  if (type === actions.AUTH_INIT_ERROR) {
    return {
      ...state,
      currentUser: null,
      loadingInit: false,
    };
  }

  return state;
};
