import authAxios from "src/modules/shared/axios/authAxios";

export default class studentsService {
  static async edit(id, data) {
    const {ingles, portugues, espanol, imagen} = data;
    let formData = new FormData();

    console.log(ingles, portugues, espanol);

    formData.append("ingles", ingles);
    formData.append("portugues", portugues);
    formData.append("espanol", espanol);

 
    const studentDto = JSON.stringify(data);
    const blob = new Blob([studentDto], {
      type: "application/json",
    });

    formData.append("student", blob);

    const response = await authAxios.put(`/student/${id}`, formData);

    return response.data;

  }

  static async exportCSVAll(filter){
    const response = await authAxios.post(`/student/exportCSVAll`,
      { filter },
      {responseType: 'blob'});
    const fileURL = window.URL.createObjectURL(new Blob([response.data]));
    const fileLink = document.createElement('a');
    fileLink.href = fileURL;
    fileLink.setAttribute('download', 'student-placement.csv');
    document.body.appendChild(fileLink);
    fileLink.click();
  }
  
  static async analitics(cohorts){
    const response = await authAxios.post("/student/analytics/",cohorts);
    return response.data;
  }
  

  static async analiticsCompanies(companies){
    const response = await authAxios.post("/student/analytics/companies",companies);
    return response.data;
  }
  
static async exportCSV(listIds){
    const response = await authAxios.post(`/student/exportCSV`,listIds,{responseType: 'blob'});
    const fileURL = window.URL.createObjectURL(new Blob([response.data]));
    const fileLink = document.createElement('a');
    fileLink.href = fileURL;
    fileLink.setAttribute('download', 'student-placement.csv');
    document.body.appendChild(fileLink);
    fileLink.click();
  }

  static async searchRegions(countryId ,namePrefix) {
    const params = {namePrefix,countryId}
    const response = await authAxios.get(
      `/student/geo/regions`,
      {
        params,
      },
    );
    console.log(response,"------") 
    return response.data.data;
  }

  static async searchCities(countryId, regionId, namePrefix) {
    const params = {namePrefix, countryId, regionId}
    const response = await authAxios.get(
      `/student/geo/cities`,
      {
        params
      },
    );
    
    return response.data.data;
  }

  static async searchCountries(namePrefix, cancelToken?) {
    const params = {namePrefix}
    const response = await authAxios.get(
      `/student/geo/countries`,
      {
        params,
        cancelToken
      },
    );
    
    return response.data.data;
  }
  
  static async editStatus(id, data) {
    const response = await authAxios.put(`/student/${id}/status`, {
      status: data,
    });

    return response.data;
  }
  static async editWorkExp(id, data) {
    const response = await authAxios.put(`/student/work/${id}`, data);

    return response.data;
  }
  static async editAcademicExp(id, data) {
    const response = await authAxios.put(`/student/academic/${id}`, data);

    return response.data;
  }
  static async destroy(ids) {
    const params = {
      ids,
    };

    const response = await authAxios.delete(`/student`, {
      params,
    });

    return response.data;
  }

  static async deleteStudentById(id) {
    const response = await authAxios.delete(`/student/${id}`);

    return response.data;
  }

  static async destroyWorkExp(id) {
    const response = await authAxios.delete(`/student/work/${id}`);

    return response.data;
  }
  static async destroyAcademicExp(id) {
    const response = await authAxios.delete(`/student/academic/${id}`);

    return response.data;
  }
  static async create(data) {
    const body = {
      data,
    };

    const response = await authAxios.post(`/student`, body);

    return response.data;
  }

  static async find(id) {
    const response = await authAxios.get(`/student/${id}`);
    return response.data;
  }

  
  static async fetchCompanies() {
    
    const response = await authAxios.get(`/student/company/all`, {
    });

    return response.data;
  }
  
  static async fetchCohort() {
    
    const response = await authAxios.get(`/student/program/cohort/all`, {
    });

    return response.data;
  }
  
  static async fetchUsers(filter, orderBy, limit, offset) {
    console.log(filter);
    const params = {
      filter: {
        ...filter,
        otherLanguages: filter?.otherLanguages,
        competencies: filter?.competencies,
        englishLvl: filter?.englishLvl
      },
      sort: orderBy,
      size: limit,
      page: offset,
    };
    const response = await authAxios.get(`/student/view/students`, {
      params,
    });

    return response.data;
  }
  static async fetchNames() {
    const response = await authAxios.get(`/student/names`);
    return response.data;
  }
  static async fetchCountries() {
    const response = await authAxios.get(`/student/country`);

    return response.data;
  }
  static async fetchRegions() {
    const response = await authAxios.get(`/student/regions`);

    return response.data;
  }
  static async fetchLocation() {
    const response = await authAxios.get(`/student/location`);

    return response.data;
  }

  static async fetchCvs(studentId: string) {
    const response = await authAxios.get(`/student/student/${studentId}/cvs`);

    return response.data;
  }


  static async fetchUserAutocomplete(query, limit) {
    const params = {
      query,
      limit,
    };

    const response = await authAxios.get(`/student/autocomplete`, {
      params,
    });
    return response.data;
  }

  static async findById(id) {
    const response = await authAxios.get(`/student/${id}`);
    return response.data;
  }

  static async createWorkExp(id, data) {
    const body = {
      workExperienceDtos: [data],
    };

    const response = await authAxios.put(`/student/${id}/work`, body);

    return response.data;
  }

  static async createAcademicExp(id, data) {
    const body = {
      academicExperienceDtos: [data],
    };

    const response = await authAxios.put(`/student/${id}/academic`, body);

    return response.data;
  }

  static async addReference(id, data, route) {
    const body = {
      referenceDtos: [data],
    };

    const response = await authAxios.put(
      `/student/${route}/${id}/reference`,
      body
    );

    return response.data;
  }
}
