import Errors from 'src/modules/shared/error/errors';
import Message from 'src/view/shared/message';
import TechnologyService from 'src/modules/technologies/technologiesService';
import { getHistory } from 'src/modules/store';
import { i18n } from 'src/i18n';

const prefix = 'CAREERS_LIST';

const technologiesListActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  ADD_STARTED: `${prefix}_ADD_STARTED`,
  ADD_SUCCESS: `${prefix}_ADD_SUCCESS`,
  ADD_ERROR: `${prefix}_ADD_ERROR`,

  ADD_TIER_STARTED: `${prefix}_ADD_TIER_STARTED`,
  ADD_TIER_SUCCESS: `${prefix}_ADD_TIER_SUCCESS`,
  ADD_TIER_ERROR: `${prefix}_ADD_TIER_ERROR`,

  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: technologiesListActions.FIND_STARTED,
      });

      const technologies = await TechnologyService.find(id);

      dispatch({
        type: technologiesListActions.FIND_SUCCESS,
        payload: technologies,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: technologiesListActions.FIND_ERROR,
      });

      getHistory().push('/technologies');
    }
  },

  doFetch: () => async (
    dispatch,
    getState,
  ) => {
    try {
      dispatch({
        type: technologiesListActions.FETCH_STARTED,
      });

      const response = await TechnologyService.fetchTechnologies();
      dispatch({
        type: technologiesListActions.FETCH_SUCCESS,
        payload: {
          technologies: response,
        },
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: technologiesListActions.FETCH_ERROR,
      });
    }
  },

  doAdd: (values) => async (dispatch) => {
    try {
      dispatch({
        type: technologiesListActions.ADD_STARTED,
      });

      const res = await TechnologyService.createTechnology(values);

      dispatch({
        type: technologiesListActions.ADD_SUCCESS,
      });

      Message.success(i18n(res));

      getHistory().push('/technologies/');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: technologiesListActions.ADD_ERROR,
      });
    }
  },

  doDelete: (id) => async (dispatch) => {
    try {
      const res = await TechnologyService.deleteTechnology(id);

      Message.success(i18n(res));

    } catch (error) {
      Errors.handle(error);
    }
  },

  doAddTier: (technologiesId) => async (dispatch) => {
    try {
      dispatch({
        type: technologiesListActions.ADD_TIER_STARTED,
      });

      await TechnologyService.createTier(technologiesId);

      dispatch({
        type: technologiesListActions.ADD_TIER_SUCCESS,
      });

      Message.success(i18n('technology.addTierSuccess'));

    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: technologiesListActions.ADD_TIER_ERROR,
      });
    }
  },

  doDeleteTier: (technologiesId) => async (dispatch) => {
    try {
      const res = await TechnologyService.deleteTier(technologiesId);

      Message.success(i18n(res));

    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: technologiesListActions.ADD_TIER_ERROR,
      });
    }
  }

};

export default technologiesListActions;