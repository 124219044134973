import actions from 'src/modules/cohorts/form/cohortsFormActions'

const initialData = {
  loading: false,
  saveLoading: false,
  refreshCohorts: false,
  cohorts: [] as Array<any>,
  cohort: {} as Object,
};

export default (state = initialData, { type, payload }) => {
  if (type === actions.FETCH_STARTED) {
    return {
      ...state,
      loading: true,
    };
  }

  if (type === actions.FETCH_SUCCESS) {
    return {
      ...state,
      loading: false,
      cohorts: payload.cohorts,
    };
  }

  if (type === actions.FETCH_ERROR) {
    return {
      ...state,
      loading: false,
      cohorts: null,
    };
  }
  if (type === actions.FETCH_ONE_SUCCESS) {
    return {
      ...state,
      loading: false,
      cohort: payload.cohort,
    };
  }

  if (type === actions.FETCH_ONE_ERROR) {
    return {
      ...state,
      loading: false,
      cohort: null,
    };
  }

  if (type === actions.ADD_STARTED) {
    return {
      ...state,
      saveLoading: true,
    };
  }

  if (type === actions.ADD_SUCCESS) {
    return {
      ...state,
      saveLoading: false,
    };
  }

  if (type === actions.ADD_ERROR) {
    return {
      ...state,
      saveLoading: false,
    };
  }

  if (type === actions.ADD_STARTED) {
    return {
      ...state,
      saveLoading: true,
    };
  }

  if (type === actions.ADD_SUCCESS) {
    return {
      ...state,
      saveLoading: false,
    };
  }

  if (type === actions.ADD_ERROR) {
    return {
      ...state,
      saveLoading: false,
    };
  }

  if (type === actions.DELETE_STARTED) {
    return {
      ...state,
      saveLoading: true,
    };
  }

  if (type === actions.DELETE_SUCCESS) {
    return {
      ...state,
      saveLoading: false,
      refreshCohorts: true,
    };
  }

  if (type === actions.DELETE_ERROR) {
    return {
      ...state,
      saveLoading: false,
    };
  }

  return state;
};