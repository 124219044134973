import Errors from '../../shared/error/errors';
import Message from '../../../view/shared/message';
import CompaniesService from '../../companies/companiesService';
import { getHistory } from '../../store';
import { i18n } from '../../../i18n';
import authSelectors from '../../auth/authSelectors';
import authActions from '../../auth/authActions';

const prefix = 'COMPANIES_FORM';

const companiesFormActions = {
    INIT_STARTED: `${prefix}_INIT_STARTED`,
    INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
    INIT_ERROR: `${prefix}_INIT_ERROR`,

    ADD_STARTED: `${prefix}_ADD_STARTED`,
    ADD_SUCCESS: `${prefix}_ADD_SUCCESS`,
    ADD_ERROR: `${prefix}_ADD_ERROR`,

    UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
    UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
    UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

    doInit: (id?) => async (dispatch) => {
        try {
            dispatch({
                type: companiesFormActions.INIT_STARTED,
            });

            const isEdit = Boolean(id);
            let record = {};

            if (isEdit) {
                record = await CompaniesService.find(id);
            }

            dispatch({
                type: companiesFormActions.INIT_SUCCESS,
                payload: record,
            });
        } catch (error) {
            Errors.handle(error);

            dispatch({
                type: companiesFormActions.INIT_ERROR,
            });

            getHistory().push('/companies');
        }
    },

    doAdd: (values) => async (dispatch) => {
        try {
            dispatch({
                type: companiesFormActions.ADD_STARTED,
            });

            await CompaniesService.create(values);

            dispatch({
                type: companiesFormActions.ADD_SUCCESS,
            });

            Message.success(i18n('companies.doAddSuccess'));

            getHistory().push('/companies');
        } catch (error) {
            Errors.handle(error);

            dispatch({
                type: companiesFormActions.ADD_ERROR,
            });
        }
    },

    doUpdate: (id, values) => async (dispatch, getState) => {
        try {
            dispatch({
                type: companiesFormActions.UPDATE_STARTED,
            });

            await CompaniesService.edit(id, values);

            dispatch({
                type: companiesFormActions.UPDATE_SUCCESS,
            });

            const currentcompanies = authSelectors.selectCurrentUser(
                getState(),
            );

            if (currentcompanies.id === values.id) {
                await dispatch(authActions.doRefreshCurrentUser());
            }

            Message.success(i18n('Empresa editada con éxito'));

            getHistory().push(`/companies/${id}`);
        } catch (error) {
            let messageError = Errors.handle(error); 
            messageError && Message.error(messageError);

            dispatch({
                type: companiesFormActions.UPDATE_ERROR,
            });
        }
    },
    
    doCreate: (data) => async (dispatch, getState) => {
        try {
            dispatch({
                type: companiesFormActions.UPDATE_STARTED,
            });

            await CompaniesService.create(data);

            dispatch({
                type: companiesFormActions.UPDATE_SUCCESS,
            });

            Message.success(i18n('Empresa creada con éxito'));

            getHistory().push('/companies');
        } catch (error) {
            let messageError = Errors.handle(error); 
            messageError && Message.error(messageError);
            console.log(error)
            dispatch({
                type: companiesFormActions.UPDATE_ERROR,
            });
        }
    },
};

export default companiesFormActions;
