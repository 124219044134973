import authAxios from 'src/modules/shared/axios/authAxios';

export default class ProgramsService {

  static async find(id) {
    const response = await authAxios.get(
      `student/technology/${id}`,
    );
    return response.data;
  }

  static async fetchTechnologies() {
    const response = await authAxios.get(
      `student/program/technology/`,
    );
    return response.data
  }

  static async createTechnology(data) {
    const response = await authAxios.post(
      `student/program/technology/`,
      data,
    );
    return response.data
  }

  static async deleteTechnology(id) {
    const response = await authAxios.delete(
      `student/program/technology/${id}`
    );
    return response.data
  }

  static async fetchPrograms() {
    const response = await authAxios.get(
      `student/program/`,
    );
    return response.data
  }

  static async createTier({ technologyIdOne, technologyIdTwo }) {

    const params = {
      technologyIdOne,
      technologyIdTwo
    }

    const response = await authAxios.post(
      `student/program/technology/tier`, {},
      { params }
    );
    return response.data
  }

  static async deleteTier({ technologyIdOne, technologyIdTwo }) {

    const params = {
      technologyIdOne,
      technologyIdTwo
    }

    const response = await authAxios.delete(
      `student/program/technology/tier`, 
      { params }
    );
    return response.data
  }

}