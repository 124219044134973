import Errors from 'src/modules/shared/error/errors';
import Message from 'src/view/shared/message';
import StudentsService from 'src/modules/students/studentsService';
import { getHistory } from 'src/modules/store';
import { i18n } from 'src/i18n';
import authSelectors from 'src/modules/auth/authSelectors';
import authActions from 'src/modules/auth/authActions';
import selectors from 'src/modules/students/form/studentsFormSelectors';
import Axios from 'axios';
import studentsListSelectors from '../list/studentsListSelectors';

const prefix = 'STUDENTS_FORM';

const studentsFormActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  ADD_STARTED: `${prefix}_ADD_STARTED`,
  ADD_SUCCESS: `${prefix}_ADD_SUCCESS`,
  ADD_ERROR: `${prefix}_ADD_ERROR`,
  
  SEARCH_CITY_STARTED: `${prefix}_SEARCH_CITY_STARTED`,
  SEARCH_CITY_SUCCESS: `${prefix}_SEARCH_CITY_ADD_SUCCESS`,

  SEARCH_REGION_STARTED: `${prefix}_SEARCH_REGION_STARTED`,
  SEARCH_REGION_SUCCESS: `${prefix}_SEARCH_REGION_SUCCESS`,

  SEARCH_COUNTRY_STARTED: `${prefix}_ADD_STARTED`,
  SEARCH_COUNTRY_SUCCESS: `${prefix}_ADD_SUCCESS`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  EXPORT_STARTED: `${prefix}_EXPORT_STARTED`,
  EXPORT_SUCCESS: `${prefix}_EXPORT_SUCCESS`,
  EXPORT_ERROR: `${prefix}_EXPORT_ERROR`,
  
  EXPORT_ALL_STARTED: `${prefix}_EXPORT_ALL_STARTED`,
  EXPORT_ALL_SUCCESS: `${prefix}_EXPORT_ALL_SUCCESS`,
  EXPORT_ALL_ERROR: `${prefix}_EXPORT_ALL_ERROR`,
  
  DESTROY_STARTED: `${prefix}_DESTROY_STARTED`,
  DESTROY_SUCCESS: `${prefix}_DESTROY_SUCCESS`,
  DESTROY_ERROR: `${prefix}_DESTROY_ERROR`,

  UPDATE_CITY_TOKEN: `${prefix}_UPDATE_CITY_TOKEN`,
  UPDATE_COUNTRY_TOKEN: `${prefix}_UPDATE_COUNTRY_TOKEN`,
  
  UPDATE_SELECT_STUDENT: `${prefix}_UPDATE_SELECT_STUDENT`,
  
  doExportAll: () => async (dispatch, getState) => {
    try {
      dispatch({
        type: studentsFormActions.EXPORT_ALL_STARTED,
      });

      const filter = studentsListSelectors.selectFilter(getState());
      await StudentsService.exportCSVAll(filter);

      dispatch({
        type: studentsFormActions.EXPORT_ALL_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: studentsFormActions.EXPORT_ALL_ERROR,
      });
    }
  },
  
  doExport: () => async (dispatch, getState) => {
    try {
      dispatch({
        type: studentsFormActions.EXPORT_STARTED,
      });

      const selectStudents = selectors.selectCheckStudents(getState());
      await StudentsService.exportCSV(selectStudents);

      dispatch({
        type: studentsFormActions.EXPORT_SUCCESS,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: studentsFormActions.EXPORT_ERROR,
      });
    }
  },
  
  sellectStudents: (id) => (dispatch, getState) => {
    const selectStudents = selectors.selectCheckStudents(getState());
    dispatch({
      type: studentsFormActions.UPDATE_SELECT_STUDENT,
      payload: selectStudents.includes(id)? 
        selectStudents.filter(studentId => studentId != id):
        [...selectStudents,id],
    });
  },

  searchCity: (prefixName="", countryId, regionId) => async (dispatch,getState) => {
    try{
      dispatch({
        type: studentsFormActions.SEARCH_CITY_STARTED,
      });
      const [currentCountry] = selectors.selectCountries(getState()).filter(({ label }) => label ===  countryId);
      const [currentRegion] = selectors.selectRegions(getState()).filter(({ label }) => label ===  regionId);

      console.log(prefixName, currentCountry, currentRegion, regionId);
      if(!Boolean(currentCountry) || !Boolean(currentRegion)) return;

      console.log(prefixName, currentCountry, currentRegion);

      const cities = await StudentsService.searchCities(
          currentCountry?.code, currentRegion?.code, prefixName) ;
      
      dispatch({
        type: studentsFormActions.SEARCH_CITY_SUCCESS,
        payload: cities?.map(({ name }) => ({label:name, value: name})),
      });
    } catch (error) {
      console.log(error)
    }
  },


  searchRegions: (prefixName="", countryId) => async (dispatch,getState) => {
    try{
      dispatch({
        type: studentsFormActions.SEARCH_REGION_STARTED,
      });
      console.log(countryId,"asdaskmñ`"); 
      const [currentCountry] = selectors.selectCountries(getState()).filter(({ label })=> label === countryId);
      let cancelToken = selectors.selectCityToken(getState()); 
      
      if(!Boolean(currentCountry)) return; 
      
      dispatch({
        type: studentsFormActions.UPDATE_CITY_TOKEN,
        payload: cancelToken,
      })

      const regions = await StudentsService.searchRegions(currentCountry?.code, prefixName) ;
      console.log(regions); 
      
      dispatch({
        type: studentsFormActions.SEARCH_REGION_SUCCESS,
        payload: regions?.map(({fipsCode, name}) => ({
          label: name,
          value: name,
          code: fipsCode
        })),
      });
    } catch (error) {
      console.log(error)
    }
  },

  startLoadingCountry: () => (dispatch) => {    
    dispatch({
      type: studentsFormActions.SEARCH_COUNTRY_STARTED,
    });
  },

  startLoadingCities: () => (dispatch) => {    
    dispatch({
      type: studentsFormActions.SEARCH_CITY_STARTED,
    });
  },

  startLoadingRegions: () => (dispatch) => {    
    dispatch({
      type: studentsFormActions.SEARCH_REGION_STARTED,
    });
  },

  searchCountry: (prefixName="") => async (dispatch, getState) => {
    try{
      dispatch(studentsFormActions.startLoadingCountry());
      
      let cancelToken = selectors.selectCountryToken(getState()); 
  
      if(cancelToken)
        cancelToken.cancel();

      cancelToken = Axios.CancelToken.source();

      dispatch({
        type: studentsFormActions.UPDATE_COUNTRY_TOKEN,
        payload: cancelToken,
      })

      const countries = await StudentsService.searchCountries(prefixName, cancelToken.token);
      console.log(countries,"·"); 
      dispatch({
        type: studentsFormActions.SEARCH_COUNTRY_SUCCESS,
        payload: countries?.map(({code,name}) => ({label:name, code, value: name})),
      });
    } catch(error) {
      console.log(error)
    }
  },


  doInit: (id?) => async (dispatch) => {
    try {
      dispatch({
        type: studentsFormActions.INIT_STARTED,
      });

      const isEdit = Boolean(id);
      let record = {};

      if (isEdit) {
        record = await StudentsService.find(id);
      }

      dispatch({
        type: studentsFormActions.INIT_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: studentsFormActions.INIT_ERROR,
      });

      getHistory().goBack('/students');
    }
  },

  doAdd: (values) => async (dispatch) => {
    try {
      dispatch({
        type: studentsFormActions.ADD_STARTED,
      });

      await StudentsService.create(values);

      dispatch({
        type: studentsFormActions.ADD_SUCCESS,
      });

      Message.success(i18n('students.doAddSuccess'));

      getHistory().push('/students');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: studentsFormActions.ADD_ERROR,
      });
    }
  },

  doUpdate: (id, values) => async (dispatch, getState) => {
    try {
      dispatch({
        type: studentsFormActions.UPDATE_STARTED,
      });

      await StudentsService.edit(id, values);
      dispatch(authActions.doInit());
      dispatch({
        type: studentsFormActions.UPDATE_SUCCESS,
      });

      const currentStudents = authSelectors.selectCurrentUser(
        getState(),
      );

      if (currentStudents.id === values.id) {
        await dispatch(authActions.doRefreshCurrentUser());
      }

      Message.success(i18n('students.doUpdateSuccess'));

      // getHistory().push('/students');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: studentsFormActions.UPDATE_ERROR,
      });
    }
  },
  doUpdateWorkExp: (id, values) => async (dispatch, getState) => {
    try {
      dispatch({
        type: studentsFormActions.UPDATE_STARTED,
      });

      await StudentsService.editWorkExp(id, values);

      dispatch({
        type: studentsFormActions.UPDATE_SUCCESS,
      });

      const currentStudents = authSelectors.selectCurrentUser(
        getState(),
      );

      if (currentStudents.id === values.id) {
        await dispatch(authActions.doRefreshCurrentUser());
      }

      Message.success(i18n('students.doUpdateSuccess'));

      // getHistory().push('/students');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: studentsFormActions.UPDATE_ERROR,
      });
    }
  },
  doAddWorkExp: (id, values) => async (dispatch) => {
    try {
      const response = await StudentsService.createWorkExp(id, values);

      Message.success(i18n(response));

    } catch (error) {
      Errors.handle(error);
    }
  },
  doDestroyWorkExp: (id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: studentsFormActions.DESTROY_STARTED,
      });

      await StudentsService.destroyWorkExp([id]);

      dispatch({
        type: studentsFormActions.DESTROY_SUCCESS,
      });
      await dispatch(authActions.doRefreshCurrentUser());
      Message.success(i18n('Experiencia eliminada con éxito'));
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: studentsFormActions.DESTROY_ERROR,
      });

    }
  },
  doUpdateAcademicExp: (id, values) => async (dispatch, getState) => {
    try {
      dispatch({
        type: studentsFormActions.UPDATE_STARTED,
      });

      await StudentsService.editAcademicExp(id, values);

      dispatch({
        type: studentsFormActions.UPDATE_SUCCESS,
      });

      const currentStudents = authSelectors.selectCurrentUser(
        getState(),
      );

      if (currentStudents.id === values.id) {
        await dispatch(authActions.doRefreshCurrentUser());
      }

      Message.success(i18n('students.doUpdateSuccess'));

      // getHistory().push('/students');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: studentsFormActions.UPDATE_ERROR,
      });
    }
  },
  doAddAcademicExp: (id, values) => async (dispatch) => {
    try {
      const response = await StudentsService.createAcademicExp(id, values);

      Message.success(i18n(response));

    } catch (error) {
      Errors.handle(error);
    }
  },
  doUpdateStatus: (id, values) => async (dispatch, getState) => {
    try {
      dispatch({
        type: studentsFormActions.UPDATE_STARTED,
      });

      await StudentsService.editStatus(id, values);

      dispatch({
        type: studentsFormActions.UPDATE_SUCCESS,
      });

      const currentStudents = authSelectors.selectCurrentUser(
        getState(),
      );

      if (currentStudents.id === values.id) {
        await dispatch(authActions.doRefreshCurrentUser());
      }

      Message.success(i18n('Estado actualizado con éxito'));

      // getHistory().push('/students');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: studentsFormActions.UPDATE_ERROR,
      });
    }
  },
  doDestroyAcademicExp: (id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: studentsFormActions.DESTROY_STARTED,
      });

      await StudentsService.destroyAcademicExp([id]);

      dispatch({
        type: studentsFormActions.DESTROY_SUCCESS,
      });
      await dispatch(authActions.doRefreshCurrentUser());
      Message.success(i18n('Experiencia eliminada con éxito'));
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: studentsFormActions.DESTROY_ERROR,
      });

    }
  },
  doAddReference: (id, values, route) => async (dispatch) => {
    try {
      const response = await StudentsService.addReference(id, values, route);

      Message.success(i18n(response));

    } catch (error) {
      Errors.handle(error);
    }
  },

};

export default studentsFormActions;
