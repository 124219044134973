import authAxios from '../../modules/shared/axios/authAxios';

export default class companiesService {
    static async edit(id, values) {
        const response = await authAxios.put(
            `/student/company/${id}`,
            values,
        );
        return response.data;
    }

    static async getCompanySelect(){
        const response = await authAxios.get(`/student/company/select-company/`);
        return response.data;
    }

    static async exportCSV(id){
        const response = await authAxios.get(`/student/company/exportCSV/${id}`,{responseType: 'blob'});
        const fileURL = window.URL.createObjectURL(new Blob([response.data]))
        const fileLink = document.createElement('a')
        fileLink.href = fileURL
        fileLink.setAttribute('download', 'student.csv')
        document.body.appendChild(fileLink)
        fileLink.click()
        console.log(response)
    }

    static async contactingCompany(companyId: string, studentId: string){
        const response = await authAxios.put(
            `/student/company/${companyId}/student/${studentId}/`
        );

        return response.data;
    }

    static async updateStatus (id) {
        const response = await authAxios.put(
            `/student/company/update-status/${id}/`);

        return response.data;
    }
    static async destroy(ids) {
        const params = {
            ids,
        };

        const response = await authAxios.delete(
            `/student/company`,
            {
                params,
            },
        );

        return response.data;
    }

    static async create(data) {
        const body = data;

        const response = await authAxios.post(
            `/student/company/`,
            body,
        );

        return response.data;
    }

    static async find(id) {
        const response = await authAxios.get(
            `/student/company/${id}`,
        );
        return response.data;
    }

    static async fetchUsers(filter, orderBy, limit, offset) {
        const params = {
            filter,
            sort: orderBy,
            size: limit,
            page: offset,
        };

        const response = await authAxios.get(
            `/student/view/companies`,
            {
                params,
            },
        );

        return response.data;
    }
    static async fetchCountries() {
        const response = await authAxios.get(
            `/student/company/country`,
        );
        return response.data;
    }
    static async fetchCities() {
        const response = await authAxios.get(
            `/student/company/city`,
        );

        return response.data;
    }
    static async fetchUserAutocomplete(query, limit) {
        const params = {
            query,
            limit,
        };

        const response = await authAxios.get(
            `/student/company/autocomplete`,
            {
                params,
            },
        );
        return response.data;
    }
}
