import CompaniesService from '../../companies/companiesService';
import selectors from '../../companies/list/companiesListSelectors';
import Errors from '../../shared/error/errors';
import Message from '../../../view/shared/message';
import authSelectors from 'src/modules/auth/authSelectors';
import { i18n } from '../../../i18n';

const prefix = 'COMPANIES_LIST';

const companiesListActions = {
    FETCH_STARTED: `${prefix}_FETCH_STARTED`,
    FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
    FETCH_ERROR: `${prefix}_FETCH_ERROR`,

    RESETED: `${prefix}_RESETED`,
    TOGGLE_ONE_SELECTED: `${prefix}_TOGGLE_ONE_SELECTED`,
    TOGGLE_ALL_SELECTED: `${prefix}_TOGGLE_ALL_SELECTED`,
    CLEAR_ALL_SELECTED: `${prefix}_CLEAR_ALL_SELECTED`,

    PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,
    SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,

    DESTROY_ALL_SELECTED_STARTED: `${prefix}_DESTROY_ALL_SELECTED_STARTED`,
    DESTROY_ALL_SELECTED_SUCCESS: `${prefix}_DESTROY_ALL_SELECTED_SUCCESS`,
    DESTROY_ALL_SELECTED_ERROR: `${prefix}_DESTROY_ALL_SELECTED_ERROR`,

    DESTROY_STARTED: `${prefix}_DESTROY_STARTED`,
    DESTROY_SUCCESS: `${prefix}_DESTROY_SUCCESS`,
    DESTROY_ERROR: `${prefix}_DESTROY_ERROR`,

    doClearAllSelected() {
        return {
            type: companiesListActions.CLEAR_ALL_SELECTED,
        };
    },

    doToggleAllSelected() {
        return {
            type: companiesListActions.TOGGLE_ALL_SELECTED,
        };
    },

    doToggleOneSelected(id) {
        return {
            type: companiesListActions.TOGGLE_ONE_SELECTED,
            payload: id,
        };
    },

    doReset: () => async (dispatch) => {
        dispatch({
            type: companiesListActions.RESETED,
        });

        dispatch(companiesListActions.doFetch());
    },

    doContacting: (companyId) => async (dispatch, getState) => {
        const studentId = authSelectors.selectCurrentUserId(getState());
        await CompaniesService.contactingCompany(companyId, studentId);
        dispatch(companiesListActions.doFetchCurrentFilter());
    },

    doChangePagination: (pagination) => async (
        dispatch,
        getState,
    ) => {
        dispatch({
            type: companiesListActions.PAGINATION_CHANGED,
            payload: pagination,
        });

        dispatch(companiesListActions.doFetchCurrentFilter());
    },

    doChangeSort: (sorter) => async (dispatch, getState) => {
        dispatch({
            type: companiesListActions.SORTER_CHANGED,
            payload: sorter,
        });

        dispatch(companiesListActions.doFetchCurrentFilter());
    },

    doFetchCurrentFilter: () => async (
        dispatch,
        getState,
    ) => {
        const filter = selectors.selectFilter(getState());
        const rawFilter = selectors.selectRawFilter(getState());
        dispatch(companiesListActions.doFetch(filter, rawFilter, true));
    },

    doFetch: (filter?, rawFilter?, keepPagination = false) => async (
        dispatch,
        getState,
    ) => {
        try {
            dispatch({
                type: companiesListActions.FETCH_STARTED,
                payload: { filter, rawFilter, keepPagination },
            });

            const response = await CompaniesService.fetchUsers(
                filter,
                selectors.selectOrderBy(getState()),
                selectors.selectLimit(getState()),
                selectors.selectOffset(getState()),
            );
            const countries = await CompaniesService.fetchCountries()
            const cities = await CompaniesService.fetchCities()
            dispatch({
                type: companiesListActions.FETCH_SUCCESS,
                payload: {
                    rows: response.rows,
                    count: response.count,
                    countries,
                    cities
                },
            });
        } catch (error) {
            Errors.handle(error);

            dispatch({
                type: companiesListActions.FETCH_ERROR,
            });
        }
    },

    updateStatus: (id) => async (dispatch, getState) => {
        try {
            let message = await CompaniesService.updateStatus(id);
            Message.success(i18n(message));
            dispatch(companiesListActions.doFetchCurrentFilter());
        } catch (error) {
            Message.error(i18n("Error al cambiar el estado"))
        }
    },

    doDestroy: (id) => async (dispatch, getState) => {
        try {
            dispatch({
                type: companiesListActions.DESTROY_STARTED,
            });

            await CompaniesService.destroy([id]);

            dispatch({
                type: companiesListActions.DESTROY_SUCCESS,
            });

            Message.success(i18n('user.doDestroySuccess'));

            dispatch(companiesListActions.doFetchCurrentFilter());
        } catch (error) {
            Errors.handle(error);

            dispatch({
                type: companiesListActions.DESTROY_ERROR,
            });

            dispatch(companiesListActions.doFetchCurrentFilter());
        }
    },

    doDestroyAllSelected: () => async (
        dispatch,
        getState,
    ) => {
        try {
            const selectedRows = selectors.selectSelectedRows(
                getState(),
            );

            dispatch({
                type: companiesListActions.DESTROY_ALL_SELECTED_STARTED,
            });

            await CompaniesService.destroy(
                selectedRows.map((row) => row.id),
            );

            dispatch({
                type: companiesListActions.DESTROY_ALL_SELECTED_SUCCESS,
            });

            Message.success(
                i18n('user.doDestroyAllSelectedSuccess'),
            );

            dispatch(companiesListActions.doFetchCurrentFilter());
        } catch (error) {
            Errors.handle(error);

            dispatch({
                type: companiesListActions.DESTROY_ALL_SELECTED_ERROR,
            });

            dispatch(companiesListActions.doFetchCurrentFilter());
        }
    },
};

export default companiesListActions;
