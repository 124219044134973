import Errors from '../../shared/error/errors';
import Message from '../../../view/shared/message';
import PlacementService from '../../placements/placementsService';
import { getHistory } from '../../store';
import { i18n } from '../../../i18n';
import authSelectors from '../../auth/authSelectors';
import actionsList from 'src/modules/placements/list/placementsListActions';


const prefix = 'PLACEMENTS_FORM';

const placementsFormActions = {
    INIT_STARTED: `${prefix}_INIT_STARTED`,
    INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
    INIT_ERROR: `${prefix}_INIT_ERROR`,

    ADD_STARTED: `${prefix}_ADD_STARTED`,
    ADD_SUCCESS: `${prefix}_ADD_SUCCESS`,
    ADD_ERROR: `${prefix}_ADD_ERROR`,
    
    ADD_OTHER_STARTED: `${prefix}_ADD_OTHER_STARTED`,
    ADD_OTHER_SUCCESS: `${prefix}_ADD_OTHER_SUCCESS`,
    ADD_OTHER_ERROR: `${prefix}_ADD_OTHER_ERROR`,

    UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
    UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
    UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

    doInit: (id?) => async (dispatch) => {
        try {
            dispatch({
                type: placementsFormActions.INIT_STARTED,
            });

            const isEdit = Boolean(id);
            let record = {};

            if (isEdit) {
                record = await PlacementService.find(id);
            }

            dispatch({
                type: placementsFormActions.INIT_SUCCESS,
                payload: record,
            });
        } catch (error) {
            Errors.handle(error);
            dispatch({
                type: placementsFormActions.INIT_ERROR,
            });
            getHistory().push('/companies');
        }
    },
    doStudentProcessCreate: (data) => async (dispatch, getState) => {
        try {
            dispatch({
                type: placementsFormActions.UPDATE_STARTED,
            });

            await PlacementService.doStudentProcessCreate(data)

            dispatch({
                type: placementsFormActions.UPDATE_SUCCESS,
            });

            Message.success(i18n('Solicitud enviada a la empresa'));
            // getHistory().push('/');
        } catch (error) {
            Errors.handle(error);
            dispatch({
                type: placementsFormActions.UPDATE_ERROR,
            });
        }
    },

    doOtherCreate: (data) => async (dispatch, getState) => {
        try {
            dispatch({type: placementsFormActions.ADD_OTHER_STARTED});

            data.studentFullName = authSelectors.selectCurrentUserFullName(getState());
            data.studentId = authSelectors.selectCurrentUserId(getState());
            data.companyId = data.ecosystemCompanies;
            data.studentFeedback = data.description;
            data.companyName = data.nameCompany;
            
            await PlacementService.doOtherCreate(data);

            dispatch({type: placementsFormActions.ADD_OTHER_SUCCESS});
            dispatch(actionsList.doFetch(data.studentId));
        } catch (error) {
            Errors.handle(error);
            dispatch({
                type: placementsFormActions.ADD_OTHER_ERROR,
            });
        }
    },


    doStudentProccess: (condition, data, data2, studentId) => async (dispatch, getState) => {
        try {
            dispatch({
                type: placementsFormActions.UPDATE_STARTED,
                });

            if (condition === 'next') {
                await PlacementService.doUpdateProccess(data);
                await PlacementService.doCreateProccess(data2);
            } else {
                await PlacementService.doUpdateProccess(data);
            }

            dispatch(actionsList.doFetch2(studentId))
            dispatch({
                type: placementsFormActions.UPDATE_SUCCESS,
            });

            Message.success(i18n('Etapa del proceso cargada con éxito'));

        } catch (error) {
            Errors.handle(error);
            dispatch({
                type: placementsFormActions.UPDATE_ERROR,
            });
        }
    },

};

export default placementsFormActions;
