import list from './list/companiesListReducers';
import form from './form/companiesFormReducers';
import view from './view/companiesViewReducers';
import { combineReducers } from 'redux';

export default combineReducers({
    list,
    form,
    view,
});
