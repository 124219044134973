import authAxios from 'src/modules/shared/axios/authAxios';

export default class ProgramsService {

  static async find(id) {
    const response = await authAxios.get(
      `student/parameters/${id}`,
    );
    return response.data;
  }

  static async fetchCareers(name) {
    const response = await authAxios.get(
      `student/parameters/?name=${name}`,
    );
    return response.data
  }

  static async createCareer(data) {
    const response = await authAxios.post(
      `student/parameters/`,
      data,
    );
    return response.data
  }

  static async deleteCareer(id) {
    const response = await authAxios.delete(
      `student/parameters/${id}`
    );
    return response.data
  }

}