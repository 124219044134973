import Header from 'src/view/layout/Header';
import Menu from 'src/view/layout/Menu';
import { makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import UserMenu from 'src/view/layout/userMenu/UserMenu';
import mhlogo from 'src/images/mhlogo.png';
import layoutActions from 'src/modules/layout/layoutActions';

const useStyles = makeStyles((theme) => ({
  root: {
    color: 'rgba(0, 0, 0, 0.65)',
    // backgroundColor: 'red',
    backgroundColor: '#fff',
    display: 'flex',
    fontFamily: `'Roboto', sans-serif`,
    '& h1, h2, h3, h4, h5, h6': {
      color: 'rgba(0, 0, 0, 0.85)',
    },
  },
  content: {
    backgroundColor: '#f0f2f5',
    borderTopLeftRadius: '40px',
    flexGrow: 1,
    marginTop: '10vh',
    minHeight: '90vh',
    overflowX: 'hidden',
    padding: theme.spacing(3),
    border: 'solid 1px #eee',
    boxShadow: 'inset 1px 1px 4px -1px #8E8E8E',
  },
  headerSpace: {
    backgroundColor: 'yellow',
    width: '100%',
    height: '10vh'
  },
  toolbar: theme.mixins.toolbar,
  imgContainer: {
    margin: '2vh 0 1vh 2vw',
    zIndex: 1201,
    height: '50px',
    cursor: 'pointer',
    position: 'fixed'
  }
}));

function Layout(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const match = useRouteMatch();

  const doToggleMenu = () => {
    dispatch(layoutActions.doToggleMenu());
  };

  return (
    <div className={classes.root}>
      <div className={classes.imgContainer} onClick={doToggleMenu}>
        <img src={mhlogo} width="100" height="50" />
      </div>
      <Menu url={match.url} />
      <UserMenu />
      <div className={classes.content}>
        {/* <div className={classes.toolbar}></div> */}
        {props.children}
      </div>

    </div>
  );
}

export default Layout;
