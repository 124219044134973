import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  makeStyles,
  Menu,
  MenuItem,
  Avatar,
  IconButton,
} from '@material-ui/core';
import authSelectors from 'src/modules/auth/authSelectors';
import { getHistory } from 'src/modules/store';
import authActions from 'src/modules/auth/authActions';
import { i18n } from 'src/i18n';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';



const useStyles = makeStyles((theme) => ({
  optionText: {
    margin: theme.spacing(0, 0.5, 0, 1),
  },
}));

export const UserPic = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  const userAvatar = useSelector(authSelectors.selectCurrentUserAvatar);
  const user = useSelector(authSelectors.selectCurrentUser);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const doSignout = () => {
    dispatch(authActions.doSignout());
  };

  const doNavigateToProfile = () => {
    getHistory().push('/profile');
  };

  return (
    < >
      <IconButton
        aria-label="add"
        size="small"
        onClick={handleClick}
        style={{
          backgroundColor: 'transparent',
          color: '#333'
        }}
      >
        {
          userAvatar
            ? <Avatar src={userAvatar} alt="avatar" />
            : <AccountCircleIcon fontSize="large" style={{ fontSize: '2.5rem' }} />
        }
      </IconButton  >

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={doNavigateToProfile}>
          <PersonOutlineIcon />
          <span className={classes.optionText}>
            {i18n('auth.profile.title')}
          </span>
        </MenuItem>
        <MenuItem onClick={doSignout}>
          <ExitToAppIcon />
          <span className={classes.optionText}>
            {i18n('auth.signout')}
          </span>
        </MenuItem>
      </Menu>
    </>
  )
}
