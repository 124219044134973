import { createSelector } from 'reselect';
import Permissions from 'src/security/permissions';
import PermissionChecker from 'src/modules/auth/permissionChecker';

const selectRaw = (state) => state.auth;

const selectAuthenticationUser = createSelector(
  [selectRaw],
  (auth) => auth.authenticationUser,
);

const selectLoadingPassword = createSelector(
  [selectRaw],
  (auth) => auth.loadingPassword,
);

const selectPhotoProfile = createSelector(
  [selectRaw],
  (auth) => auth?.photoProfile
);

const selectCurrentUser = createSelector(
  [selectRaw],
  (auth) => auth.currentUser,
);

const selectCurrentUserEmail = createSelector(
  [selectCurrentUser],
  (currentUser) => (currentUser ? currentUser.email : null),
);

const selectCurrentUserId = createSelector(
  [selectCurrentUser],
  (currentUser) => (currentUser ? currentUser.personalId : null),
);

const selectCurrentUserFullName = createSelector(
  [selectCurrentUser],
  ({name, lastName}) =>
    name ? name+" "+lastName: '',
);

const selectHasCompany = createSelector(
  [selectCurrentUser], 
  ({roles}) => roles.some(({name}) => name === "COMPANY")
);

const selectSignedIn = createSelector(
  [selectCurrentUser],
  (currentUser) =>
    Boolean(currentUser) && Boolean(currentUser.id),
);

const selectLoading = createSelector([selectRaw], (auth) =>
  Boolean(auth.loading),
);

const selectLoadingInit = createSelector(
  [selectRaw],
  (auth) => Boolean(auth.loadingInit),
);

const selectLoadingUpdateProfile = createSelector(
  [selectRaw],
  (auth) => Boolean(auth.loadingUpdateProfile),
);

const selectErrorMessage = createSelector(
  [selectRaw],
  (auth) => auth.errorMessage,
);

const selectIsAdmin = createSelector(
  [
    selectCurrentUser,
  ],
  (currentUser) =>
    new PermissionChecker(currentUser).match(
      Permissions.values.userEdit,
    ),
);

const selectCurrentUserNameOrEmailPrefix = createSelector(
  [selectCurrentUser, selectCurrentUserFullName],
  (currentUser, fullName) => {
    if (!currentUser) {
      return '';
    }

    if (fullName && fullName.length < 25) {
      return fullName;
    }

    if (currentUser.firstName) {
      return currentUser.firstName;
    }

    /*return currentUser.email.split('@')[0];*/
  },
);

const selectCurrentUserAvatar = createSelector(
  [selectCurrentUser],
  (currentUser) => {
    if (
      !currentUser ||
      !currentUser.avatars ||
      !currentUser.avatars.length ||
      !currentUser.avatars[0].downloadUrl
    ) {
      return null;
    }

    return currentUser.avatars[0].downloadUrl;
  },
);


const authSelectors = {
  selectLoadingInit,
  selectLoadingUpdateProfile,
  selectLoading,
  selectSignedIn,
  selectCurrentUserFullName,
  selectCurrentUserEmail,
  selectCurrentUser,
  selectAuthenticationUser,
  selectErrorMessage,
  selectRaw,
  selectLoadingPassword,
  selectCurrentUserNameOrEmailPrefix,
  selectCurrentUserAvatar,
  selectCurrentUserId,
  selectHasCompany,
  selectIsAdmin,
  selectPhotoProfile
};

export default authSelectors;
