import placementsService from '../../placements/placementsService';
import companyService from 'src/modules/companies/companiesService';
import selectors from '../../companies/list/companiesListSelectors';
import Errors from '../../shared/error/errors';
import Message from '../../../view/shared/message';
import { i18n } from '../../../i18n';


const prefix = 'PLACEMENTS_LIST';

const placementsListActions = {
    FETCH_STARTED: `${prefix}_FETCH_STARTED`,
    FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
    FETCH_ALL_SUCCESS: `${prefix}_FETCH_ALL_SUCCESS`,
    FETCH_SUCCESS2: `${prefix}_FETCH_SUCCESS2`,
    FETCH_ERROR: `${prefix}_FETCH_ERROR`,
    FETCH_ALL_ERROR: `${prefix}_FETCH_ALL_ERROR`,

    RESETED: `${prefix}_RESETED`,
    TOGGLE_ONE_SELECTED: `${prefix}_TOGGLE_ONE_SELECTED`,
    TOGGLE_ALL_SELECTED: `${prefix}_TOGGLE_ALL_SELECTED`,
    CLEAR_ALL_SELECTED: `${prefix}_CLEAR_ALL_SELECTED`,

    PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,
    SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,

    DESTROY_ALL_SELECTED_STARTED: `${prefix}_DESTROY_ALL_SELECTED_STARTED`,
    DESTROY_ALL_SELECTED_SUCCESS: `${prefix}_DESTROY_ALL_SELECTED_SUCCESS`,
    DESTROY_ALL_SELECTED_ERROR: `${prefix}_DESTROY_ALL_SELECTED_ERROR`,

    DESTROY_STARTED: `${prefix}_DESTROY_STARTED`,
    DESTROY_SUCCESS: `${prefix}_DESTROY_SUCCESS`,
    DESTROY_ERROR: `${prefix}_DESTROY_ERROR`,

    LOAD_COMPANY_SELECT: `${prefix}_LOAD_SELECT`,

    EXPORT_STARTED: `${prefix}_EXPORT_STARTED`,
    EXPORT_SUCCESS: `${prefix}_EXPORT_SUCCESS`,
    EXPORT_ERROR: `${prefix}_EXPORT_ERROR`,

    doExport: (contactId: String) => async (dispatch) => {
        try {
            dispatch({type: placementsListActions.EXPORT_STARTED});
            await companyService.exportCSV(contactId);
            dispatch({type: placementsListActions.EXPORT_SUCCESS}); 
        } catch (error) {
            Errors.handle(error);
            dispatch({type: placementsListActions.EXPORT_ERROR});
        }
    },


    doClearAllSelected() {
        return {
            type: placementsListActions.CLEAR_ALL_SELECTED,
        };
    },

    doToggleAllSelected() {
        return {
            type: placementsListActions.TOGGLE_ALL_SELECTED,
        };
    },

    doToggleOneSelected(id) {
        return {
            type: placementsListActions.TOGGLE_ONE_SELECTED,
            payload: id,
        };
    },

    doReset: (studentId) => async (dispatch) => {
        dispatch({
            type: placementsListActions.RESETED,
        });

        dispatch(placementsListActions.doFetch(studentId));
    },

    doChangePagination: (pagination) => async (
        dispatch,
        getState,
    ) => {
        dispatch({
            type: placementsListActions.PAGINATION_CHANGED,
            payload: pagination,
        });

        dispatch(placementsListActions.doFetchCurrentFilter());
    },

    doChangeSort: (sorter) => async (dispatch, getState) => {
        dispatch({
            type: placementsListActions.SORTER_CHANGED,
            payload: sorter,
        });

        dispatch(placementsListActions.doFetchCurrentFilter());
    },

    doFetchCurrentFilter: () => async (
        dispatch,
        getState,
    ) => {
        const filter = selectors.selectFilter(getState());
        const rawFilter = selectors.selectRawFilter(getState());
        dispatch(placementsListActions.doFetch(filter, rawFilter, true));
    },
    
    doLoadSelectCompany: () => async (
        dispatch
    ) => {
        const data = await companyService.getCompanySelect();
        
        dispatch({
            type: placementsListActions.LOAD_COMPANY_SELECT,
            payload: data,
        })
    },

    doFetch: (studentId, filter?, rawFilter?, keepPagination = false) => async (
        dispatch,
        getState,
    ) => {

        try {
            dispatch({
                type: placementsListActions.FETCH_STARTED,
                payload: { filter, rawFilter, keepPagination },
            });

            const response = await placementsService.fetchPlacements(
                studentId,
                filter,
                selectors.selectOrderBy(getState()),
                selectors.selectLimit(getState()),
                selectors.selectOffset(getState()),
            );
            dispatch({
                type: placementsListActions.FETCH_SUCCESS,
                payload: {
                    rows: response,
                    count: response.count,
                },
            });
        } catch (error) {
            Errors.handle(error);

            dispatch({
                type: placementsListActions.FETCH_ERROR,
            });
        }
    },
    doFetchForCompany: (companyId, filter?, rawFilter?, keepPagination = false) => async (
        dispatch,
        getState,
    ) => {

        try {
            dispatch({
                type: placementsListActions.FETCH_STARTED,
                payload: { filter, rawFilter, keepPagination },
            });

            const response = await placementsService.fetchPlacementsForCompany(
                companyId,
                filter,
                selectors.selectOrderBy(getState()),
                selectors.selectLimit(getState()),
                selectors.selectOffset(getState()),
            );
            dispatch({
                type: placementsListActions.FETCH_SUCCESS,
                payload: {
                    rows: response,
                    count: response.count,
                },
            });
        } catch (error) {
            Errors.handle(error);

            dispatch({
                type: placementsListActions.FETCH_ERROR,
            });
        }
    },
    doFetch2: (studentId, filter?, rawFilter?, keepPagination = false) => async (
        dispatch,
        getState,
    ) => {

        try {

            const response = await placementsService.fetchPlacements(
                studentId,
                filter,
                selectors.selectOrderBy(getState()),
                selectors.selectLimit(getState()),
                selectors.selectOffset(getState()),
            );
            dispatch({
                type: placementsListActions.FETCH_SUCCESS2,
                payload: {
                    rows: response,
                    count: response.count,
                },
            });
        } catch (error) {
            Errors.handle(error);

            dispatch({
                type: placementsListActions.FETCH_ERROR,
            });
        }
    },
    doFetchAll: (filter?, rawFilter?, keepPagination = false) => async (
        dispatch,
        getState,
    ) => {

        try {
            dispatch({
                type: placementsListActions.FETCH_STARTED,
                payload: { filter, rawFilter, keepPagination },
            });

            const response = await placementsService.fetchAllPlacements(
                filter,
                selectors.selectOrderBy(getState()),
                selectors.selectLimit(getState()),
                selectors.selectOffset(getState()),
            );
            dispatch({
                type: placementsListActions.FETCH_ALL_SUCCESS,
                payload: {
                    rows: response,
                    count: response.count,
                },
            });
        } catch (error) {
            Errors.handle(error);

            dispatch({
                type: placementsListActions.FETCH_ALL_ERROR,
            });
        }
    },
    doDestroy: (id) => async (dispatch, getState) => {
        try {
            dispatch({
                type: placementsListActions.DESTROY_STARTED,
            });

            await placementsService.destroy([id]);

            dispatch({
                type: placementsListActions.DESTROY_SUCCESS,
            });

            Message.success(i18n('user.doDestroySuccess'));

            dispatch(placementsListActions.doFetchCurrentFilter());
        } catch (error) {
            Errors.handle(error);

            dispatch({
                type: placementsListActions.DESTROY_ERROR,
            });

            dispatch(placementsListActions.doFetchCurrentFilter());
        }
    },

    doDestroyAllSelected: () => async (
        dispatch,
        getState,
    ) => {
        try {
            const selectedRows = selectors.selectSelectedRows(
                getState(),
            );

            dispatch({
                type: placementsListActions.DESTROY_ALL_SELECTED_STARTED,
            });

            await placementsService.destroy(
                selectedRows.map((row) => row.id),
            );

            dispatch({
                type: placementsListActions.DESTROY_ALL_SELECTED_SUCCESS,
            });

            Message.success(
                i18n('user.doDestroyAllSelectedSuccess'),
            );

            dispatch(placementsListActions.doFetchCurrentFilter());
        } catch (error) {
            Errors.handle(error);

            dispatch({
                type: placementsListActions.DESTROY_ALL_SELECTED_ERROR,
            });

            dispatch(placementsListActions.doFetchCurrentFilter());
        }
    },
};

export default placementsListActions;
