import Permissions from 'src/security/permissions';
import { i18n } from 'src/i18n';
import React from 'react';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PersonIcon from '@material-ui/icons/Person';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import CreateIcon from '@material-ui/icons/Create';
import LaptopChromebookIcon from '@material-ui/icons/LaptopChromebook';
import CalendarViewDayIcon from '@material-ui/icons/CalendarViewDay';
import GamesIcon from '@material-ui/icons/Games';
import AirplayIcon from '@material-ui/icons/Airplay';
import BusinessIcon from '@material-ui/icons/Business';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';

const permissions = Permissions.values;

export default [
  {
    path: '/',
    exact: true,
    icon: <DashboardIcon />,
    label: i18n('dashboard.menu'),
    permissionRequired: null,
  },
  // {
  //   path: '/user',
  //   label: i18n('user.menu'),
  //   permissionRequired: permissions.userRead,
  //   icon: <PersonIcon />,
  // },
  {
    path: '/students',
    label: i18n('students.menu'),
    permissionRequired: permissions.studentRead,
    icon: <PersonOutlineIcon />,
  },
  {
    path: '/companies',
    label: i18n('companies.menu'),
    permissionRequired: permissions.userRead,
    icon: <LaptopChromebookIcon />,
  },
  {
    path: '/calendar',
    label: i18n('Calendario'),
    permissionRequired: permissions.userRead,
    icon: <CalendarTodayIcon />,
  },

  {
    path: '/placement',
    label: i18n('placement.students'),
    permissionRequired: permissions.userRead,
    icon: <BusinessIcon />,
  },
  {
    path: '/placement-companies',
    label: i18n('placement.companies'),
    permissionRequired: permissions.userRead,
    icon: <BusinessIcon />,
  },
  // {
  //   path: '/analytics',
  //   label: i18n('analytics.menu'),
  //   permissionRequired: permissions.userRead,
  //   icon: <CalendarViewDayIcon />,
  // },
  // {
  //   path: '/proyects',
  //   label: i18n('proyects.menu'),
  //   permissionRequired: permissions.userRead,
  //   icon: <CreateIcon />,
  // },
  {
    path: '/technologies',
    label: i18n('technology.menu'),
    permissionRequired: permissions.userRead,
    icon: <GamesIcon />,
  },
  {
    path: '/parameters',
    label: i18n('parameters.menu'),
    permissionRequired: permissions.userRead,
    icon: <GamesIcon />,
  },
  {
    path: '/mentors',
    label: i18n('mentors.menu'),
    permissionRequired: permissions.userRead,
    icon: <SupervisorAccountIcon />,
  },
  {
    path: '/programs',
    label: i18n('programs.menu'),
    permissionRequired: permissions.userRead,
    icon: <CalendarTodayIcon />,
  },
  {
    path: '/cohorts',
    label: i18n('cohort.menu'),
    permissionRequired: permissions.userRead,
    icon: <AirplayIcon />,
  }
].filter(Boolean);
