import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';
import cohortsService from 'src/modules/cohorts/cohortsService';

const prefix = 'COHORTS_VIEW';

const cohortsViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  GET_COHORT_CALENDER: `${prefix}_GET_CALENDER`,

  getAllCalender: (range= "CURRENT") => async (dispatch) => {
    
    const response = await cohortsService.fetchCohortsCalender(range);

    dispatch({
      type: cohortsViewActions.GET_COHORT_CALENDER,
      payload: response,
    });
  },
  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: cohortsViewActions.FIND_STARTED,
      });

      const cohort = await cohortsService.find(id);
      console.log(cohort)
      dispatch({
        type: cohortsViewActions.FIND_SUCCESS,
        payload: cohort,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: cohortsViewActions.FIND_ERROR,
      });

      getHistory().push('/cohorts');
    }
  },
  doFind2: (id) => async (dispatch) => {
    try {
      const cohort = await cohortsService.find(id);
      console.log(cohort)
      dispatch({
        type: cohortsViewActions.FIND_SUCCESS,
        payload: cohort,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: cohortsViewActions.FIND_ERROR,
      });

      getHistory().push('/cohorts');
    }
  },
};

export default cohortsViewActions;
