import Errors from 'src/modules/shared/error/errors';
import Message from 'src/view/shared/message';
import { getHistory } from 'src/modules/store';
import { i18n } from 'src/i18n';
import MentorService from 'src/modules/mentors/mentorsService';

const prefix = 'MENTOR_LIST';

const mentorsListActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  ADD_STARTED: `${prefix}_ADD_STARTED`,
  ADD_SUCCESS: `${prefix}_ADD_SUCCESS`,
  ADD_ERROR: `${prefix}_ADD_ERROR`,

  EDIT_STARTED: `${prefix}_EDIT_STARTED`,
  EDIT_SUCCESS: `${prefix}_EDIT_SUCCESS`,
  EDIT_ERROR: `${prefix}_EDIT_ERROR`,

  DELETE_STARTED: `${prefix}_DELETE_STARTED`,
  DELETE_SUCCESS: `${prefix}_DELETE_SUCCESS`,
  DELETE_ERROR: `${prefix}_DELETE_ERROR`,

  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: mentorsListActions.FIND_STARTED,
      });

      const mentors = await MentorService.find(id);

      dispatch({
        type: mentorsListActions.FIND_SUCCESS,
        payload: mentors,
      });

    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: mentorsListActions.FIND_ERROR,
      });

      getHistory().push('/mentors');
    }
  },

  doFetch: () => async (dispatch, getState,) => {
    try {
      dispatch({
        type: mentorsListActions.FETCH_STARTED,
      });

      const res = await MentorService.fetchMentors();
      
      dispatch({
        type: mentorsListActions.FETCH_SUCCESS,
        payload: {
          mentors: res,
        },
      });

    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: mentorsListActions.FETCH_ERROR,
      });
    }
  },

  doAdd: (values) => async (dispatch) => {
    try {
      dispatch({
        type: mentorsListActions.ADD_STARTED,
      });

      await MentorService.addMentor(values);

      dispatch({
        type: mentorsListActions.ADD_SUCCESS,
      });

      Message.success(i18n('mentors.doAddSuccess'));

      getHistory().push('/mentors');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: mentorsListActions.ADD_ERROR,
      });
    }
  },

  doDelete: (id) => async (dispatch) => {
    try {
      dispatch({
        type: mentorsListActions.DELETE_STARTED,
      });

      await MentorService.deleteMentor(id);

      dispatch({
        type: mentorsListActions.DELETE_SUCCESS,
      });

      // Message.success(i18n('mentors.addTierSuccess'));

      // getHistory().push('/technologies/');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: mentorsListActions.DELETE_ERROR,
      });
    }
  },
  
  doModify: (id, data) => async (dispatch) => {
    try {
      dispatch({
        type: mentorsListActions.EDIT_STARTED,
      });

      const res = await MentorService.modifyMentor(id, data);

      dispatch({
        type: mentorsListActions.EDIT_SUCCESS,
      });

      Message.success(i18n(res));

      getHistory().push('/mentors');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: mentorsListActions.EDIT_ERROR,
      });
    }
  }

};

export default mentorsListActions;