import Errors from 'src/modules/shared/error/errors';
import Message from 'src/view/shared/message';
import careersService from 'src/modules/careers/careersService';
import { getHistory } from 'src/modules/store';
import { i18n } from 'src/i18n';

const prefix = 'CAREERS';

const careersListActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  ADD_STARTED: `${prefix}_ADD_STARTED`,
  ADD_SUCCESS: `${prefix}_ADD_SUCCESS`,
  ADD_ERROR: `${prefix}_ADD_ERROR`,

  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: careersListActions.FIND_STARTED,
      });

      const careers = await careersService.find(id);

      dispatch({
        type: careersListActions.FIND_SUCCESS,
        payload: careers,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: careersListActions.FIND_ERROR,
      });

      getHistory().push('/parameters');
    }
  },

  doFetch: (name?) => async (
    dispatch,
    getState,
  ) => {
    try {
      dispatch({
        type: careersListActions.FETCH_STARTED,
      });

      const response = await careersService.fetchCareers(name);
      
      dispatch({
        type: careersListActions.FETCH_SUCCESS,
        payload: {
          careers: response,
        },
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: careersListActions.FETCH_ERROR,
      });
    }
  },

  doAdd: (values) => async (dispatch) => {
    try {
      dispatch({
        type: careersListActions.ADD_STARTED,
      });

      const res = await careersService.createCareer(values);

      dispatch({
        type: careersListActions.ADD_SUCCESS,
      });

      Message.success(i18n(res));

      // getHistory().push('/parameters/');
    } catch (error) {
      if(error?.response?.data === "Codigo ingresado ya existe") {
        Message.error(error?.response?.data);
      } else {
        Errors.handle(error);

        dispatch({
          type: careersListActions.ADD_ERROR,
        });
      }      
    }
  },

  doDelete: (id) => async (dispatch) => {
    try {
      const res = await careersService.deleteCareer(id);
      console.log(res)
      Message.success(i18n(res));

    } catch (error) {
      Errors.handle(error);
    }
  },

};

export default careersListActions;