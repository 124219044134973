import service from "src/modules/auth/authService";
import Errors from "src/modules/shared/error/errors";
import Message from "src/view/shared/message";
import { i18n } from "src/i18n";
import { getHistory } from "src/modules/store";
import { AuthToken } from "src/modules/auth/authToken";
import Swal from "sweetalert2";

const prefix = "AUTH";

const authActions = {
  ERROR_MESSAGE_CLEARED: `${prefix}_ERROR_MESSAGE_CLEARED`,

  AUTH_INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  AUTH_INIT_ERROR: `${prefix}_INIT_ERROR`,

  AUTH_START: `${prefix}_START`,
  AUTH_SUCCESS: `${prefix}_SUCCESS`,
  AUTH_ERROR: `${prefix}_ERROR`,

  UPDATE_PASSWORD_START: `${prefix}_UPDATE_PASSWORD_START`,
  UPDATE_PASSWORD_SUCCESS: `${prefix}_UPDATE_PASSWORD_SUCCESS`,
  UPDATE_PASSWORD_ERROR: `${prefix}_UPDATE_PASSWORD_ERROR`,

  UPDATE_PROFILE_START: `${prefix}_UPDATE_PROFILE_START`,
  UPDATE_PROFILE_SUCCESS: `${prefix}_UPDATE_PROFILE_SUCCESS`,
  UPDATE_PROFILE_ERROR: `${prefix}_UPDATE_PROFILE_ERROR`,

  CURRENT_USER_REFRESH_START: `${prefix}_CURRENT_USER_REFRESH_START`,
  CURRENT_USER_REFRESH_SUCCESS: `${prefix}_CURRENT_USER_REFRESH_SUCCESS`,
  CURRENT_USER_REFRESH_ERROR: `${prefix}_CURRENT_USER_REFRESH_ERROR`,

  PHOTO_PROFILE: `${prefix}_PHOTO_PROFILE`,

  doClearErrorMessage() {
    return {
      type: authActions.ERROR_MESSAGE_CLEARED,
    };
  },

  doRegisterStudent: (newUser) => async (dispatch) => {
    try {
      dispatch({
        type: authActions.AUTH_START,
      });

      await service.signUpStudent(newUser);

      getHistory().push("/");

      Swal.fire({
        title:
          "Registración exitosa. Recibirás el usuario y contraseña en tu casilla de email",
        timer: 5000,
        showConfirmButton: false,
        background: "#191919",
        color: "#FFFFFF",
      });

      dispatch({
        type: authActions.AUTH_SUCCESS,
        payload: { currentUser: null },
      });
    } catch (error) {
      if (error?.response && error?.response?.status === 403) {
        Message.error(i18n(error?.response.data));
      } else {
        Errors.handle(error);
      }

      dispatch({
        type: authActions.AUTH_ERROR,
      });
    }
  },

  resetPassword: (email) => async (dispatch) => {
    try {
      dispatch({
        type: authActions.UPDATE_PASSWORD_START,
      });

      await service.resetPassword(email);

      dispatch({ type: authActions.UPDATE_PASSWORD_SUCCESS });

      Message.success(i18n("auth.resetPasswordMessege"));
      getHistory().push("/auth/signin");
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: authActions.UPDATE_PASSWORD_ERROR,
      });
    }
  },

  updatePassword: (data, handleClose) => async (dispatch) => {
    try {
      dispatch({
        type: authActions.UPDATE_PASSWORD_START,
      });

      let response = await service.updatePassword(data);

      dispatch({ type: authActions.UPDATE_PASSWORD_SUCCESS });

      Message.success(i18n(`auth.${response}`));
      handleClose();
    } catch (error) {
      let message = Errors.handle(error);
      message && Message.error(i18n(`auth.${message}`));
      handleClose();
      dispatch({
        type: authActions.UPDATE_PASSWORD_ERROR,
      });
    }
  },

  doSigninWithEmailAndPassword: (email, password, rememberMe) => async (
    dispatch
  ) => {
    try {
      dispatch({ type: authActions.AUTH_START });

      let currentUser = null;

      const accessData = await service.signinWithEmailAndPassword(
        email,
        password
      );
      const token = accessData.access_token;
      AuthToken.set(token, rememberMe);
      currentUser = await service.fetchMe();

      dispatch({
        type: authActions.AUTH_SUCCESS,
        payload: {
          currentUser,
        },
      });
    } catch (error) {
      console.log(error);
      await service.signout();

      if (Errors.errorCode(error) !== 400) {
        Errors.handle(error);
      }

      dispatch({
        type: authActions.AUTH_ERROR,
        payload: Errors.selectMessage(error),
      });
    }
  },

  doSignout: () => async (dispatch) => {
    try {
      dispatch({ type: authActions.AUTH_START });
      await service.signout();

      dispatch({
        type: authActions.AUTH_SUCCESS,
        payload: {
          currentUser: null,
        },
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: authActions.AUTH_ERROR,
      });
    }
  },

  doInit: () => async (dispatch) => {
    try {
      const token = AuthToken.get();
      let currentUser = null;

      if (token) {
        currentUser = await service.fetchMe();
      }

      dispatch({
        type: authActions.AUTH_INIT_SUCCESS,
        payload: {
          currentUser,
        },
      });
    } catch (error) {
      service.signout();
      Errors.handle(error);

      dispatch({
        type: authActions.AUTH_INIT_ERROR,
        payload: error,
      });
    }
  },

  doRefreshCurrentUser: () => async (dispatch) => {
    try {
      dispatch({
        type: authActions.CURRENT_USER_REFRESH_START,
      });

      let currentUser = null;
      const token = AuthToken.get();

      if (token) {
        currentUser = await service.fetchMe();
      }

      dispatch({
        type: authActions.CURRENT_USER_REFRESH_SUCCESS,
        payload: {
          currentUser,
        },
      });
    } catch (error) {
      service.signout();
      Errors.handle(error);

      dispatch({
        type: authActions.CURRENT_USER_REFRESH_ERROR,
        payload: error,
      });
    }
  },

  doUpdateProfile: (data) => async (dispatch) => {
    try {
      dispatch({
        type: authActions.UPDATE_PROFILE_START,
      });

      await service.updateProfile(data);

      dispatch({
        type: authActions.UPDATE_PROFILE_SUCCESS,
      });
      await dispatch(authActions.doRefreshCurrentUser());
      Message.success(i18n("auth.photo.success"));
      getHistory().push("/");
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: authActions.UPDATE_PROFILE_ERROR,
      });
    }
  },

  doDeletePhotoProfile: (id) => async (dispatch) => {
    try {
      await service.deletePhotoProfile(id);

      Message.success(i18n("auth.photo.success_delete"));
    } catch (error) {
      Errors.handle(error);
    }
  },
  doResetPhotoProfile: () => async (dispatch) => {
    try {
      dispatch({
        type: authActions.PHOTO_PROFILE,
        payload: {
          photo: null,
        },
      });

      // return response
    } catch (error) {
      Errors.handle(error);
    }
  },

  doGetPhotoProfile: (id) => async (dispatch) => {
    try {
      const response = await service.getPhotoProfile(id);

      dispatch({
        type: authActions.PHOTO_PROFILE,
        payload: {
          photo: response,
        },
      });

      // return response
    } catch (error) {
      if (error?.response && error?.response?.status === 404) {
        console.log(error?.response);
        return null;
      }
      if (error?.response && error?.response?.status === 400) {
        console.log("400");
        return null;
      }
      if (
        error?.response &&
        error?.response?.message ===
          "Se produjo un error al procesar la solicitud."
      ) {
        return;
      }
      console.log(error);
      // Errors.handle(error);
    }
  },

  doUpdatePhotoProfile: (id, fileView) => async (dispatch) => {
    try {
      await service.updatePhotoProfile(id, fileView);

      Message.success(i18n("auth.photo.success"));
    } catch (error) {
      Errors.handle(error);
    }
  },
};

export default authActions;
