import actions from '../view/companiesViewActions';

const initialData = {
    loading: false,
    companies: null,
};

export default (state = initialData, { type, payload }) => {
    if (type === actions.FIND_STARTED) {
        return {
            ...state,
            companies: null,
            loading: true,
        };
    }

    if (type === actions.FIND_SUCCESS) {
        return {
            ...state,
            companies: payload,
            loading: false,
        };
    }

    if (type === actions.FIND_ERROR) {
        return {
            ...state,
            companies: null,
            loading: false,
        };
    }

    return state;
};
