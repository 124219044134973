import { i18n } from 'src/i18n';

class Roles {
  static get values() {
    return {
      admin: 'ADMIN',
      custom: 'custom',
      company: 'COMPANY',
      student: 'STUDENT',
      mentor: 'MENTOR',
      user: 'USER',
      operational : 'OPERATIONAL'
    };
  }

  static labelOf(roleId) {
    if (!this.values[roleId]) {
      return roleId;
    }

    return i18n(`roles.${roleId}.label`);
  }

  static descriptionOf(roleId) {
    if (!this.values[roleId]) {
      return roleId;
    }

    return i18n(`roles.${roleId}.description`);
  }
}

export default Roles;