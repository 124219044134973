import actions from 'src/modules/mentors/list/mentorsListActions';

const initialData = {
  loading: false,
  saveLoading: false,
  mentors: [] as Array<any>,
  // technologiesNames: [] as Array<any>
};

export default (state = initialData, { type, payload }) => {
  if (type === actions.FETCH_STARTED) {
    return {
      ...state,
      loading: true,
    };
  }

  if (type === actions.FETCH_SUCCESS) {
    return {
      ...state,
      loading: false,
      mentors: payload.mentors,
      // technologiesNames: payload.technologies.map(technology => technology.name)
    };
  }

  if (type === actions.FETCH_ERROR) {
    return {
      ...state,
      loading: false,
      mentors: [],
    };
  }

  if (type === actions.ADD_STARTED) {
    return {
      ...state,
      saveLoading: true,
    };
  }

  if (type === actions.ADD_SUCCESS) {
    return {
      ...state,
      saveLoading: false,
    };
  }

  if (type === actions.ADD_ERROR) {
    return {
      ...state,
      saveLoading: false,
    };
  }

  if (type === actions.EDIT_STARTED) {
    return {
      ...state,
      saveLoading: true,
    };
  }

  if (type === actions.EDIT_SUCCESS) {
    return {
      ...state,
      saveLoading: false,
    };
  }

  if (type === actions.EDIT_ERROR) {
    return {
      ...state,
      saveLoading: false,
    };
  }

  if (type === actions.DELETE_STARTED) {
    return {
      ...state,
      saveLoading: true,
    };
  }

  if (type === actions.DELETE_SUCCESS) {
    return {
      ...state,
      saveLoading: false,
    };
  }

  if (type === actions.DELETE_ERROR) {
    return {
      ...state,
      saveLoading: false,
    };
  }

  return state;
};