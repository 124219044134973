import StudentService from "src/modules/students/studentsService";
import ProgramsService from "src/modules/technologies/technologiesService";
import selectors from "src/modules/students/list/studentsListSelectors";
import cohortsActions from "src/modules/cohorts/view/cohortsViewActions";
import Errors from "src/modules/shared/error/errors";
import Message from "src/view/shared/message";
import { i18n } from "src/i18n";
import { tr } from "date-fns/locale";

const prefix = "STUDENTS_LIST";

const studentsListActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  FETCH_CV_STARTED: `${prefix}_FETCH_CV_STARTED`,
  FETCH_CV_SUCCESS: `${prefix}_FETCH_CV_SUCCESS`,
  FETCH_CV_ERROR: `${prefix}_FETCH_CV_ERROR`,

  FINDBYID_STARTED: `${prefix}_FINDBYID_STARTED`,
  FINDBYID_SUCCESS: `${prefix}_FINDBYID_SUCCESS`,
  FINDBYID_ERROR: `${prefix}_FINDBYID_ERROR`,

  RESETED: `${prefix}_RESETED`,
  TOGGLE_ONE_SELECTED: `${prefix}_TOGGLE_ONE_SELECTED`,
  TOGGLE_ALL_SELECTED: `${prefix}_TOGGLE_ALL_SELECTED`,
  CLEAR_ALL_SELECTED: `${prefix}_CLEAR_ALL_SELECTED`,

  FETCH_PROGRAM: `${prefix}_FETCH_PROGRAM`,

  PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,
  SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,

  FETCH_COHORT: `${prefix}_FETCH_COHORT`,
  FETCH_COMPANIES: `${prefix}_FETCH_COMPANIES`,

  ADD_ANALITICS: `${prefix}_ADD_ANALITICS`,

  DESTROY_ALL_SELECTED_STARTED: `${prefix}_DESTROY_ALL_SELECTED_STARTED`,
  DESTROY_ALL_SELECTED_SUCCESS: `${prefix}_DESTROY_ALL_SELECTED_SUCCESS`,
  DESTROY_ALL_SELECTED_ERROR: `${prefix}_DESTROY_ALL_SELECTED_ERROR`,

  DESTROY_STARTED: `${prefix}_DESTROY_STARTED`,
  DESTROY_SUCCESS: `${prefix}_DESTROY_SUCCESS`,
  DESTROY_ERROR: `${prefix}_DESTROY_ERROR`,

  doFetchCvs: (studentId: string) => async (dispatch, getState) => {
    try {
      dispatch({ type: studentsListActions.FETCH_CV_STARTED });

      const cvs = await StudentService.fetchCvs(studentId);

      dispatch({
        type: studentsListActions.FETCH_CV_SUCCESS,
        payload: cvs,
      });
    } catch (error) {
      dispatch({ type: studentsListActions.FETCH_CV_ERROR });
      Errors.handle(error);
    }
  },

  doClearAllSelected() {
    return {
      type: studentsListActions.CLEAR_ALL_SELECTED,
    };
  },

  doToggleAllSelected() {
    return {
      type: studentsListActions.TOGGLE_ALL_SELECTED,
    };
  },

  doToggleOneSelected(id) {
    return {
      type: studentsListActions.TOGGLE_ONE_SELECTED,
      payload: id,
    };
  },

  saveAnalytics: (data) => (dispatch, getState) => {
    const analytics = selectors.selectAnalytics(getState());
    const { index } = data;

    if (analytics.length !== index) {
      dispatch({
        type: studentsListActions.ADD_ANALITICS,
        payload: [...analytics, data],
      });
      return;
    }
    const currentIndex = analytics.findIndex(({ index: x }) => x === index);
    analytics[currentIndex] = { ...analytics };

    dispatch({
      type: studentsListActions.ADD_ANALITICS,
      payload: [...analytics],
    });
  },

  doResetProcess: () => async (dispatch) => {
    dispatch({
      type: studentsListActions.RESETED,
    });

    dispatch(studentsListActions.doFetchProcessStudents());
  },

  doReset: () => async (dispatch) => {
    dispatch({
      type: studentsListActions.RESETED,
    });

    dispatch(studentsListActions.doFetch());
  },

  doChangeAdminPagination: (pagination) => async (dispatch, getState) => {
    dispatch({
      type: studentsListActions.PAGINATION_CHANGED,
      payload: pagination,
    });

    dispatch(studentsListActions.doFetchAdminCurrentFilter());
  },
  doChangePagination: (pagination) => async (dispatch, getState) => {
    dispatch({
      type: studentsListActions.PAGINATION_CHANGED,
      payload: pagination,
    });

    dispatch(studentsListActions.doFetchCurrentFilter());
  },

  doChangeSort: (sorter) => async (dispatch, getState) => {
    dispatch({
      type: studentsListActions.SORTER_CHANGED,
      payload: sorter,
    });

    dispatch(studentsListActions.doFetchCurrentFilter());
  },

  doFetchAdminCurrentFilter: () => async (dispatch, getState) => {
    const filter = selectors.selectFilter(getState());
    const rawFilter = selectors.selectRawFilter(getState());
    dispatch(
      studentsListActions.doFetchProcessStudents(filter, rawFilter, true)
    );
  },

  doFetchCurrentFilter: () => async (dispatch, getState) => {
    const filter = selectors.selectFilter(getState());
    const rawFilter = selectors.selectRawFilter(getState());
    dispatch(studentsListActions.doFetch(filter, rawFilter, true));
  },

  doFetchCohort: () => async (dispatch, getState) => {
    const cohorts = await StudentService.fetchCohort();
    dispatch({
      type: studentsListActions.FETCH_COHORT,
      payload: cohorts,
    });
  },

  doFetchCompanies: () => async (dispatch, getState) => {
    const cohorts = await StudentService.fetchCompanies();
    dispatch({
      type: studentsListActions.FETCH_COMPANIES,
      payload: cohorts,
    });
  },

  doFetchProgram: () => async (dispatch, getState) => {
    const programs = await ProgramsService.fetchPrograms();
    dispatch({
      type: studentsListActions.FETCH_PROGRAM,
      payload: {
        programs: programs.map(({ shortName }) => shortName),
      },
    });
  },

  doFetch:
    (filter?, rawFilter?, keepPagination = false) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: studentsListActions.FETCH_STARTED,
          payload: { filter, rawFilter, keepPagination },
        });
        console.log(filter)
        const response = await StudentService.fetchUsers(
          filter,
          selectors.selectOrderBy(getState()),
          selectors.selectLimit(getState()),
          selectors.selectOffset(getState())
        );

        const [names, countries, regions, location, technologies] =
          await Promise.all([
            StudentService.fetchNames(),
            StudentService.fetchCountries(),
            StudentService.fetchRegions(),
            StudentService.fetchLocation(),
            ProgramsService.fetchTechnologies(),
          ]);

        dispatch({
          type: studentsListActions.FETCH_SUCCESS,
          payload: {
            rows: response.tier1.rows,
            count: response.tier1.count,
            tier2: response.tier2.rows,
            tier2count: response.tier2.count,
            names,
            countries,
            regions,
            location,
            technologies: technologies.map((technology) => technology.name),
          },
        });
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: studentsListActions.FETCH_ERROR,
        });
      }
    },

  startSearchStudents: () => (dispatch) => {
    dispatch({
      type: studentsListActions.FETCH_STARTED,
    });
  },

  doFetchProcessStudents:
    (filter?, rawFilter?, keepPagination = false, limit?) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: studentsListActions.FETCH_STARTED,
          payload: { filter, rawFilter, keepPagination },
        });

        const cohorts = selectors.selectCohorts(getState());

        cohorts.length === 0 && dispatch(studentsListActions.doFetchCohort());

        const response = await StudentService.fetchUsers(
          filter,
          selectors.selectOrderBy(getState()),
          limit || selectors.selectLimit(getState()),
          selectors.selectOffset(getState())
        );

        dispatch({
          type: studentsListActions.FETCH_SUCCESS,
          payload: {
            rows: response.tier1.rows,
            count: response.tier1.count,
          },
        });
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: studentsListActions.FETCH_ERROR,
        });
      }
    },
  findById: (id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: studentsListActions.FINDBYID_STARTED,
      });

      const response = await StudentService.findById([id]);

      dispatch({
        type: studentsListActions.FINDBYID_SUCCESS,
        payload: response.data,
      });

      dispatch(studentsListActions.doFetchCurrentFilter());
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: studentsListActions.FINDBYID_ERROR,
      });

      dispatch(studentsListActions.doFetchCurrentFilter());
    }
  },

  doDeleteStudentById:
    (id, idCohort = null) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: studentsListActions.DESTROY_STARTED,
        });

        await StudentService.deleteStudentById(id);

        dispatch({
          type: studentsListActions.DESTROY_SUCCESS,
        });

        Message.success(i18n("user.doDestroySuccess"));

        idCohort || dispatch(studentsListActions.doFetchCurrentFilter());
        idCohort && dispatch(cohortsActions.doFind(idCohort));
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: studentsListActions.DESTROY_ERROR,
        });

        dispatch(studentsListActions.doFetchCurrentFilter());
      }
    },

  doDestroy: (id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: studentsListActions.DESTROY_STARTED,
      });

      await StudentService.destroy([id]);

      dispatch({
        type: studentsListActions.DESTROY_SUCCESS,
      });

      Message.success(i18n("user.doDestroySuccess"));

      dispatch(studentsListActions.doFetchCurrentFilter());
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: studentsListActions.DESTROY_ERROR,
      });

      dispatch(studentsListActions.doFetchCurrentFilter());
    }
  },

  doDestroyAllSelected: () => async (dispatch, getState) => {
    try {
      const selectedRows = selectors.selectSelectedRows(getState());

      dispatch({
        type: studentsListActions.DESTROY_ALL_SELECTED_STARTED,
      });

      await StudentService.destroy(selectedRows.map((row) => row.id));

      dispatch({
        type: studentsListActions.DESTROY_ALL_SELECTED_SUCCESS,
      });

      Message.success(i18n("user.doDestroyAllSelectedSuccess"));

      dispatch(studentsListActions.doFetchCurrentFilter());
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: studentsListActions.DESTROY_ALL_SELECTED_ERROR,
      });

      dispatch(studentsListActions.doFetchCurrentFilter());
    }
  },
};

export default studentsListActions;
