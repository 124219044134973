
import Permissions from 'src/security/permissions';

const permissions = Permissions.values;

const privateRoutes = [
  {
    path: '/',
    loader: () =>
    import('src/view/dashboard/DashboardPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/calendar',
    loader: () =>
    import('src/view/calendar/CalendarPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/profile',
    loader: () =>
    import('src/view/user/profile/ProfilePage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/user',
    loader: () => import('src/view/user/list/UserPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },
  {
    path: '/test',
    loader: () => import('src/view/elastic/list/test'),
    permissionRequired: permissions.userRead,
    exact: true,
  },
  {
    path: '/user/new',
    loader: () => import('src/view/user/new/UserNewPage'),
    permissionRequired: permissions.userCreate,
    exact: true,
  },
  {
    path: '/user/:id/edit',
    loader: () => import('src/view/user/edit/UserEditPage'),
    permissionRequired: permissions.userEdit,
    exact: true,
  },
  {
    path: '/user/:id',
    loader: () => import('src/view/user/view/UserViewPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },
  {
    path: '/analytics',
    loader: () => import('src/view/analytics/AnalyticsPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },
  {
    path: '/companies',
    loader: () => import('src/view/companies/list/CompaniesPage'),
    permissionRequired: permissions.companyRead,
    exact: true,
  },
  {
    path: '/companies/:id',
    loader: () => import('src/view/companies/view/CompaniesViewPage'),
    permissionRequired: permissions.companyRead,
    exact: true,
  },  
  {
    path: '/companie/create',
    loader: () => import('src/view/companies/NewCompanyPage'),
    permissionRequired: permissions.companyRead,
    exact: true,
  },
  {
    path: '/companies/:id/edit',
    loader: () => import('src/view/companies/edit/CompanyEditPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },
  {
    path: '/programs',
    loader: () => import('src/view/programs/ProgramsPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },
  {
    path: '/program',
    loader: () => import('src/view/programs/form/ProgramsFormPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },
  {
    path: '/program/:id',
    loader: () => import('src/view/programs/program/ProgramView'),
    permissionRequired: permissions.userRead,
    exact: true,
  },
  {
    path: '/program/:id/edit',
    loader: () => import('src/view/programs/edit/ProgramsEditFormPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },
  {
    path: '/cohorts',
    loader: () => import('src/view/cohorts/table/cohortsTablePage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },
  {
    path: '/cohort/program/:id',
    loader: () => import('src/view/cohorts/form/cohortsFormPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },
  {
    path: '/cohort/:id',
    loader: () => import('src/view/cohorts/view/CohortViewPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },
  {
    path: '/cohort/:id/edit',
    loader: () => import('src/view/cohorts/editForm/cohortsEditFormPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },
  {
    path: '/proyects',
    loader: () => import('src/view/proyects/ProyectsPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },
  {
    path: '/placement',
    loader: () => import('src/view/placement/adminStudentView/AdminStudentViewPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },
  {
    path: '/placement-cohort/:id',
    loader: () => import('src/view/placement/adminStudentView/cohort/CohortPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },
  {
    path: '/placement-student/:id/:cohortId',
    loader: () => import('src/view/placement/adminStudentView/student/StudentPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },
  {
    path: '/placement-companies',
    loader: () => import('src/view/placement/adminCompanyView/AdminCompanyViewPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },
  {
    path: '/placement-company/:id',
    loader: () => import('src/view/placement/adminCompanyView/company/CompanyPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },
  {
    path: '/students',
    loader: () => import('src/view/students/list/StudentsPage'),
    permissionRequired: permissions.studentRead,
    exact: true,
  },
  {
    path: '/students/:id',
    loader: () => import('src/view/students/view/StudentsViewPage'),
    permissionRequired: permissions.studentRead,
    exact: true,
  },
  {
    path: '/students/:id/edit',
    loader: () => import('src/view/students/edit/StudentEditPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },
  {
    path: '/technologies',
    loader: () => import('src/view/technologies/list/technologiesPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },
  {
    path: '/technology',
    loader: () => import('src/view/technologies/form/technologiesFormPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },
  {
    path: '/technology/:id',
    loader: () => import('src/view/technologies/tiers/technologiesTiersPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },
  {
    path: '/parameters',
    loader: () => import('src/view/careers/list/careersPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },
  {
    path: '/parameter',
    loader: () => import('src/view/careers/form/careersFormPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },
  {
    path: '/mentors',
    loader: () => import('src/view/mentors/list/MentorsPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },
  {
    path: '/mentor',
    loader: () => import('src/view/mentors/form/MentorsFormPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },
  {
    path: '/mentor/:id',
    loader: () => import('src/view/mentors/edit/MentorsEditFormPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },
].filter(Boolean);

const emptyPermissionsRoutes = [
  {
    path: '/auth/empty-permissions',
    loader: () =>
    import('src/view/auth/EmptyPermissionsPage'),
  },
].filter(Boolean);

const simpleRoutes = [
  {
    path: '/403',
    loader: () =>
    import('src/view/shared/errors/Error403Page'),
  },
  {
    path: '/500',
    loader: () =>
    import('src/view/shared/errors/Error500Page'),
  },
  {
    path: '**',
    loader: () =>
    import('src/view/shared/errors/Error404Page'),
  },
].filter(Boolean);

const publicRoutes = [
  {
    path: '/auth/signin',
    loader: () => import('src/view/auth/SigninPage'),
  },
  {
    path: '/auth/forgotPassword',
    loader: () => import('src/view/auth/ForgetPasswordPage'),
  },
  {
    path: '/auth/signup',
    loader: () => import('src/view/auth/SignupPage'),
  },
  
].filter(Boolean);

export default {
  privateRoutes,
  publicRoutes,
  emptyPermissionsRoutes,
  simpleRoutes,
};
