import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { IconButton, makeStyles, Typography, } from '@material-ui/core';
import authSelectors from 'src/modules/auth/authSelectors';
import { UserPic } from './UserPic';
import NotificationsIcon from '@material-ui/icons/Notifications';


const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#fff',
    // backgroundColor: '#5ab',
    borderRadius: '25px',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    position: 'fixed',
    zIndex: 1,
    left: '86%',
    top: '2%',
  },
  text: {
    minWidth: '75%',
    marginLeft: '5px',
    // margin: theme.spacing(0, 0.5, 0, 1),
    display: 'none',
    flexDirection: 'column',
    alignItems: 'flex-start',
    color: theme.palette.getContrastText(
      theme.palette.success.light,
    ),
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  userName: {
    fontWeight: 'bold',
    fontSize: '.9rem',
    color: theme.palette.getContrastText(
      theme.palette.success.light,
    ),
  },

}));

function UserMenu(props) {

  const classes = useStyles();
  const user = useSelector(authSelectors.selectCurrentUser,);

  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', scrollDown)
    window.addEventListener('scroll', scrollTop)
    return () => handleDismount()
  }, [])


  const scrollDown = () => {
    if (window.scrollY > 50 && window.scrollY < 150 && !scroll) {
      setScroll(true);
    }
  };

  const scrollTop = () => {
    if (window.scrollY < 50 ) {
      setScroll(false);
    }
  };

  const handleDismount = () => {
    window.removeEventListener('scroll', scrollDown)
    window.removeEventListener('scroll', scrollTop)
  };


  let fullName = `${user.name} ${user.lastName}`
  let nameToShow = fullName.length > 25 ? user.name : fullName

  return (
    <div
      className={classes.container}
      style={{
        boxShadow: scroll ? "2px 3px 5px -1px #8E8E8Ebb" : 'none',
        minWidth: scroll ? '3.5vw' : '15vw',
        minHeight: scroll ? '7vh' : '3vh',
        left: scroll ? '95%' : '86%',
        // flexDirection: shadow ? 'column-reverse' : 'row',
        // marginTop: shadow ? '12vh' : 0
      }}

    >
      {/* <div style={{
        backgroundColor: 'white', height: '30px', width: '30px', borderRadius: '25px'
      }}
      >
      </div> */}
      {/* <IconButton
        aria-label="add"
        size="small"
        // onClick={handleClick}
        style={{ backgroundColor: 'transparent', color: '#333' }}
      >
        <NotificationsIcon fontSize="large" />
      </IconButton  > */}
      <UserPic />
      {
        !scroll
        && (
          <div className={classes.text}>
            <span>Hola</span>
            <span className={classes.userName}>
              {nameToShow || 'Usuario'}
            </span>
          </div>
        )
      }
    </div>
  );
}

export default UserMenu;
