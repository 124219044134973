import actions from "src/modules/students/list/studentsListActions";

const INITIAL_PAGE_SIZE = 50;

const initialData = {
  rows: [] as Array<any>,
  loadingCvs: false,
  rowsCvs: [] as Array<any>,
  count: 0,
  tier2: [] as Array<any>,
  cohorts: [] as Array<any>,
  tier2count: 0,
  loading: false,
  filter: {},
  rawFilter: {},
  pagination: {
    current: 1,
    pageSize: INITIAL_PAGE_SIZE,
  },
  sorter: {},
  selectedKeys: [] as Array<any>,
  names: [] as Array<any>,
  countries: [] as Array<any>,
  regions: [] as Array<string>,
  studentById: {} as Object,
  technologies: [] as Array<any>,
  location: [] as Array<any>,
  programs: [] as Array<any>,
  analytics: [] as Array<any>,
  accountAnalytics: 1,  
  career: [] as Array<any>,
  companies: [] as Array<any>,
};

export default (state = initialData, { type, payload }) => {
  if (type === actions.FETCH_CV_STARTED) {
    return {
      ...state,
      loadingCvs: true,
    };  
  }

  if (type === actions.FETCH_CV_SUCCESS) {
    return {
      ...state,
      loadingCvs: false,
      rowsCvs: payload,
    };
  }

  if (type === actions.FETCH_CV_ERROR) {
    return {
      ...state,
      loadingCvs: false,
    };
  }

  if (type === actions.RESETED) {
    return {
      ...initialData,
    };
  }
  
  if (type === actions.ADD_ANALITICS) {
    return {
      ...state,
      accountAnalytics: payload.length,
      analytics: payload, 
    };
  }
  
  if (type === actions.TOGGLE_ONE_SELECTED) {
    let selectedKeys = state.selectedKeys;

    const exists = selectedKeys.includes(payload);

    if (exists) {
      selectedKeys = selectedKeys.filter((key) => key !== payload);
    } else {
      selectedKeys = [payload, ...selectedKeys];
    }

    return {
      ...state,
      selectedKeys,
    };
  }

  if (type === actions.TOGGLE_ALL_SELECTED) {
    const isAllSelected =
      (state.rows || []).length === (state.selectedKeys || []).length;

    return {
      ...state,
      selectedKeys: isAllSelected ? [] : state.rows.map((row) => row.id),
    };
  }

  if (type === actions.FETCH_COHORT) {
    return {
      ...state,
      cohorts: payload,
    };
  }

  if (type === actions.FETCH_COMPANIES) {
    return {
      ...state,
      companies: payload,
    };
  }
  
  if (type === actions.CLEAR_ALL_SELECTED) {
    return {
      ...state,
      selectedKeys: [],
    };
  }

  if (type === actions.PAGINATION_CHANGED) {
    return {
      ...state,
      pagination: payload || {
        current: 1,
        pageSize: INITIAL_PAGE_SIZE,
      },
    };
  }

  if (type === actions.SORTER_CHANGED) {
    return {
      ...state,
      sorter: payload || {},
    };
  }

  if (type === actions.FETCH_STARTED) {
    return {
      ...state,
      loading: true,
      selectedKeys: [],
      filter: payload ? payload.filter : {},
      rawFilter: payload ? payload.rawFilter : {},
      pagination:
        payload && payload.keepPagination
          ? state.pagination
          : {
              current: 1,
              pageSize: INITIAL_PAGE_SIZE,
            },
    };
  }

  if(type === actions.FETCH_PROGRAM){
    return {
      ...state,
      programs: payload?.programs,
    }
  }

  if (type === actions.FETCH_SUCCESS) {
    return {
      ...state,
      loading: false,
      rows: payload.rows,
      count: payload.count,
      tier2: payload.tier2,
      tier2count: payload.tier2count,
      names: payload.names,
      countries: payload.countries,
      technologies: payload.technologies,
      location: payload.location,
      regions: payload.regions
      // career: payload.careers,
    };
  }

  if (type === actions.FETCH_ERROR) {
    return {
      ...state,
      loading: false,
      rows: [],
      count: 0,
    };
  }

  if (type === actions.FINDBYID_STARTED) {
    return {
      ...state,
      loading: true,
    };
  }

  if (type === actions.FINDBYID_SUCCESS) {
    return {
      ...state,
      loading: false,
      studentById: payload,
    };
  }

  if (type === actions.FINDBYID_ERROR) {
    return {
      ...state,
      loading: false,
      studentById: {},
    };
  }

  if (type === actions.DESTROY_ALL_SELECTED_STARTED) {
    return {
      ...state,
      loading: true,
    };
  }

  if (type === actions.DESTROY_ALL_SELECTED_ERROR) {
    return {
      ...state,
      loading: false,
    };
  }

  if (type === actions.DESTROY_ALL_SELECTED_SUCCESS) {
    return {
      ...state,
      selectedKeys: [],
    };
  }

  if (type === actions.DESTROY_STARTED) {
    return {
      ...state,
      loading: true,
    };
  }

  if (type === actions.DESTROY_ERROR) {
    return {
      ...state,
      loading: false,
    };
  }

  if (type === actions.DESTROY_SUCCESS) {
    return {
      ...state,
      selectedKeys: [],
    };
  }

  return state;
};
