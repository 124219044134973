import { createSelector } from 'reselect';

const selectRaw = (state) => state.students.form;

const selectLoadingCities = createSelector(
  [selectRaw],
  (raw) => raw.loadingCities,
);

const selectLoadingCountries = createSelector(
  [selectRaw],
  (raw) => raw.loadingCountries,
);

const selectLoadingRegions = createSelector(
  [selectRaw],
  (raw) => raw.loadingRegions,
);

const selectCheckStudents = createSelector(
  [selectRaw],
  (raw) => raw.selectsStudents,
);

const selectCountries = createSelector(
  [selectRaw],
  (raw) => raw.countries || [],
);

const selectCountryToken = createSelector(
  [selectRaw],
  (raw) => raw.tokenCountries,
);

const selectCityToken = createSelector(
  [selectRaw],
  (raw) => raw.tokenCities,
);

const selectCities = createSelector(
  [selectRaw],
  (raw) => raw.cities || [],
);

const selectRegions = createSelector(
  [selectRaw],
  (raw) => raw.regions || [],
);

const selectStudents = createSelector(
  [selectRaw],
  (raw) => raw.students,
);

const selectInitLoading = createSelector(
  [selectRaw],
  (raw) => Boolean(raw.initLoading),
);

const selectSaveLoading = createSelector(
  [selectRaw],
  (raw) => Boolean(raw.saveLoading),
);

const selectExportLoading = createSelector(
  [selectRaw],
  (raw) => Boolean(raw.exportLoading),
);

const selectAllExportLoading = createSelector(
  [selectRaw],
  (raw) => Boolean(raw.allExportLoading),
);

const studentsFormSelectors = {
  selectInitLoading,
  selectSaveLoading,
  selectStudents,
  selectCities,
  selectCountries,
  selectLoadingCities,
  selectLoadingCountries,
  selectLoadingRegions,
  selectRaw,
  selectCityToken,
  selectCountryToken,
  selectCheckStudents,
  selectExportLoading,
  selectAllExportLoading,
  selectRegions 
};

export default studentsFormSelectors;
