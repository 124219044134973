import actions from '../list/placementsListActions';

const INITIAL_PAGE_SIZE = 50;

const initialData = {
    rows: [] as Array<any>,
    allRows: [] as Array<any>,
    count: 0,
    loading: false,
    loadingExport: false,
    filter: {},
    rawFilter: {},
    pagination: {
        current: 1,
        pageSize: INITIAL_PAGE_SIZE,
    },
    sorter: {},
    selectCompany: [] as Array<any>,
    selectedKeys: [] as Array<any>,
};

const placementsListReducers = (state = initialData, { type, payload }) => {
    if (type === actions.RESETED) {
        return {
            ...initialData,
        };
    }

    if (type === actions.EXPORT_STARTED) {
        return {
            ...state,
            loadingExport: true,
        };
    }
    if (type === actions.EXPORT_SUCCESS) {
        return {
            ...state,
            loadingExport: false,
        };
    }
    if (type === actions.EXPORT_ERROR) {
        return {
            ...state,
            loadingExport: false,
        };
    }
    
    if(type === actions.LOAD_COMPANY_SELECT){
        return {
            ...state,
            selectCompany: payload,
        }
    }

    if (type === actions.TOGGLE_ONE_SELECTED) {
        let selectedKeys = state.selectedKeys;

        const exists = selectedKeys.includes(payload);

        if (exists) {
            selectedKeys = selectedKeys.filter(
                (key) => key !== payload,
            );
        } else {
            selectedKeys = [payload, ...selectedKeys];
        }

        return {
            ...state,
            selectedKeys,
        };
    }

    if (type === actions.TOGGLE_ALL_SELECTED) {
        const isAllSelected =
            (state.rows || []).length ===
            (state.selectedKeys || []).length;

        return {
            ...state,
            selectedKeys: isAllSelected
                ? []
                : state.rows.map((row) => row.id),
        };
    }

    if (type === actions.CLEAR_ALL_SELECTED) {
        return {
            ...state,
            selectedKeys: [],
        };
    }

    if (type === actions.PAGINATION_CHANGED) {
        return {
            ...state,
            pagination: payload || {
                current: 1,
                pageSize: INITIAL_PAGE_SIZE,
            },
        };
    }

    if (type === actions.SORTER_CHANGED) {
        return {
            ...state,
            sorter: payload || {},
        };
    }

    if (type === actions.FETCH_STARTED) {
        return {
            ...state,
            loading: true,
            selectedKeys: [],
            filter: payload ? payload.filter : {},
            rawFilter: payload ? payload.rawFilter : {},
            pagination:
                payload && payload.keepPagination
                    ? state.pagination
                    : {
                        current: 1,
                        pageSize: INITIAL_PAGE_SIZE,
                    },
        };
    }

    if (type === actions.FETCH_SUCCESS) {
        return {
            ...state,
            loading: false,
            rows: payload.rows,
            count: payload.count,
        };
    }
    if (type === actions.FETCH_ALL_SUCCESS) {
        return {
            ...state,
            loading: false,
            allRows: payload.rows,
            count: payload.count,
        };
    }
    if (type === actions.FETCH_SUCCESS2) {
        return {
            ...state,
            rows: payload.rows,
            count: payload.count,
        };
    }
    if (type === actions.FETCH_ERROR) {
        return {
            ...state,
            loading: false,
            rows: [],
            count: 0,
        };
    }
    if (type === actions.FETCH_ALL_ERROR) {
        return {
            ...state,
            loading: false,
            allRows: [],
            count: 0,
        };
    }

    if (type === actions.DESTROY_ALL_SELECTED_STARTED) {
        return {
            ...state,
            loading: true,
        };
    }

    if (type === actions.DESTROY_ALL_SELECTED_ERROR) {
        return {
            ...state,
            loading: false,
        };
    }

    if (type === actions.DESTROY_ALL_SELECTED_SUCCESS) {
        return {
            ...state,
            selectedKeys: [],
        };
    }

    if (type === actions.DESTROY_STARTED) {
        return {
            ...state,
            loading: true,
        };
    }

    if (type === actions.DESTROY_ERROR) {
        return {
            ...state,
            loading: false,
        };
    }

    if (type === actions.DESTROY_SUCCESS) {
        return {
            ...state,
            selectedKeys: [],
        };
    }

    return state;
};

export default placementsListReducers;
