import actions from 'src/modules/students/form/studentsFormActions';

const initialData = {
  initLoading: false,
  saveLoading: false,
  loadingCities: false,
  tokenCities:null,
  tokenCountries:null,
  cities: [],
  regions: [],
  exportLoading: false,
  allExportLoading: false,
  selectsStudents: [] ,
  loadingCountries: false,
  loadingRegions: false,
  countries: [],
  user: null,
};

const studentsFormReducers = (state = initialData, { type, payload }) => {
  if (type === actions.UPDATE_CITY_TOKEN) {
    return {
      ...state,
      tokenCities: payload,
    };
  }

 if (type === actions.UPDATE_COUNTRY_TOKEN) {
    return {
      ...state,
      tokenCountries: payload,
    };
  }
  
  if (type === actions.UPDATE_SELECT_STUDENT){
    return {
      ...state,
      selectsStudents: payload  
    }
  }
  
  if (type === actions.SEARCH_CITY_STARTED) {
    return {
      ...state,
      loadingCities: true,
    };
  }

  if (type === actions.SEARCH_CITY_SUCCESS) {
    return {
      ...state,
      cities: payload,
      loadingCities: false,
    };
  }

  if (type === actions.SEARCH_REGION_STARTED) {
    return {
      ...state,
      loadingRegions: true,
    };
  }

  if (type === actions.SEARCH_REGION_SUCCESS) {
    return {
      ...state,
      regions: payload,
      loadingRegions: false,
    };
  }


  if (type === actions.SEARCH_COUNTRY_STARTED) {
    return {
      ...state,
      loadingCountries: true,
    };
  }

  if (type === actions.SEARCH_COUNTRY_SUCCESS) {
    return {
      ...state,
      countries: payload,
      loadingCountries: false,
    };
  }

  if (type === actions.INIT_STARTED) {
    return {
      ...state,
      students: null,
      initLoading: true,
    };
  }

  if (type === actions.INIT_SUCCESS) {
    return {
      ...state,
      students: payload,
      initLoading: false,
    };
  }

  if (type === actions.INIT_ERROR) {
    return {
      ...state,
      students: null,
      initLoading: false,
    };
  }

  if (type === actions.ADD_STARTED) {
    return {
      ...state,
      saveLoading: true,
    };
  }

  if (type === actions.ADD_SUCCESS) {
    return {
      ...state,
      saveLoading: false,
    };
  }

  if (type === actions.ADD_ERROR) {
    return {
      ...state,
      saveLoading: false,
    };
  }

  if (type === actions.UPDATE_STARTED) {
    return {
      ...state,
      saveLoading: true,
    };
  }

  if (type === actions.UPDATE_SUCCESS) {
    return {
      ...state,
      saveLoading: false,
    };
  }

  if (type === actions.UPDATE_ERROR) {
    return {
      ...state,
      saveLoading: false,
    };
  }

  if (type === actions.EXPORT_STARTED) {
    return {
      ...state,
      exportLoading: true,
    };
  }

  if (type === actions.EXPORT_SUCCESS) {
    return {
      ...state,
      exportLoading: false,
      selectsStudents: []
    };
  }

  if (type === actions.EXPORT_ERROR) {
    return {
      ...state,
      exportLoading: false,
    };
  }

  
  if (type === actions.EXPORT_ALL_STARTED) {
    return {
      ...state,
      allExportLoading: true,
    };
  }

  if (type === actions.EXPORT_ALL_SUCCESS) {
    return {
      ...state,
      allExportLoading: false,
    };
  }

  if (type === actions.EXPORT_ALL_ERROR) {
    return {
      ...state,
      allExportLoading: false,
    };
  }

  return state;
};

export default studentsFormReducers; 
