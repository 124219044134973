import { createSelector } from 'reselect';

const selectRaw = (state) => state.companies.list;

const selectLoading = createSelector(
    [selectRaw],
    (raw) => raw.loading,
);

const selectRows = createSelector(
    [selectRaw],
    (raw) => raw.rows,
);

const selectCount = createSelector(
    [selectRaw],
    (raw) => raw.count,
);

const selectHasRows = createSelector(
    [selectCount],
    (count) => count > 0,
);
const selectCountries = createSelector(
    [selectRaw],
    (raw) => raw.countries,
);
const selectCities = createSelector(
    [selectRaw],
    (raw) => raw.cities,
);
const selectSorter = createSelector(
    [selectRaw],
    (raw) => raw.sorter || {},
);

const selectOrderBy = createSelector([selectRaw], (raw) => {
    const sorter = raw.sorter;

    if (!sorter) {
        return null;
    }

    if (!sorter.field) {
        return null;
    }

    let direction = sorter.order === 'desc' ? 'DESC' : 'ASC';

    return `${sorter.field},${direction}`;
});

const selectFilter = createSelector([selectRaw], (raw) => {
    return raw.filter;
});

const selectRawFilter = createSelector(
    [selectRaw],
    (raw) => {
        return raw.rawFilter;
    },
);

const selectLimit = createSelector([selectRaw], (raw) => {
    const pagination = raw.pagination;
    return pagination.pageSize;
});

const selectOffset = createSelector([selectRaw], (raw) => {
    const pagination = raw.pagination;

    if (!pagination || !pagination.pageSize) {
        return 0;
    }

    const current = pagination.current || 1;

    return (current - 1);
});

const selectPagination = createSelector(
    [selectRaw, selectCount],
    (raw, count) => {
        return {
            ...raw.pagination,
            total: count,
        };
    },
);

const selectSelectedKeys = createSelector(
    [selectRaw],
    (raw) => {
        return raw.selectedKeys;
    },
);

const selectSelectedRows = createSelector(
    [selectRaw, selectRows],
    (raw, rows) => {
        return rows.filter((row) =>
            raw.selectedKeys.includes(row.id),
        );
    },
);

const selectIsAllSelected = createSelector(
    [selectRows, selectSelectedKeys],
    (rows, selectedKeys) => {
        return rows.length === selectedKeys.length;
    },
);

const companiesListSelectors = {
    selectLoading,
    selectRows,
    selectCount,
    selectOrderBy,
    selectLimit,
    selectFilter,
    selectOffset,
    selectPagination,
    selectSelectedKeys,
    selectSelectedRows,
    selectHasRows,
    selectRawFilter,
    selectIsAllSelected,
    selectSorter,
    selectCountries,
    selectCities
};

export default companiesListSelectors;
