import { connectRouter } from 'connected-react-router';
import layout from 'src/modules/layout/layoutReducers';
import auth from 'src/modules/auth/authReducers';
import user from 'src/modules/user/userReducers';
import students from 'src/modules/students/studentsReducers';
import studentInformation from 'src/modules/studentInformation/StudentInformationReducers';
import programs from 'src/modules/programs/programsReducers'
import technologies from 'src/modules/technologies/technologiesReducers'
import careers from 'src/modules/careers/careersReducers';
import cohorts from 'src/modules/cohorts/cohortsReducers'
import companies from 'src/modules/companies/companiesReducers'
import placements from 'src/modules/placements/placementsReducers'
import mentors from 'src/modules/mentors/mentorsReducers'
import { combineReducers } from 'redux';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    layout,
    auth,
    user,
    students,
    studentInformation,
    programs,
    technologies,
    cohorts,
    companies,
    placements,
    mentors,
    careers
  });
