import Errors from 'src/modules/shared/error/errors';
import Message from 'src/view/shared/message';
import ProgramsService from 'src/modules/programs/programsService';
import { getHistory } from 'src/modules/store';
import { i18n } from 'src/i18n';
import authSelectors from 'src/modules/auth/authSelectors';
import authActions from 'src/modules/auth/authActions';

const prefix = 'PROGRAM_FORM';

const programsFormActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  ADD_STARTED: `${prefix}_ADD_STARTED`,
  ADD_SUCCESS: `${prefix}_ADD_SUCCESS`,
  ADD_ERROR: `${prefix}_ADD_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  doFetch: () => async (dispatch, getState,) => {
    try {
      dispatch({
        type: programsFormActions.FETCH_STARTED,
      });

      const response = await ProgramsService.fetchPrograms();
      dispatch({
        type: programsFormActions.FETCH_SUCCESS,
        payload: {
          programs: response,
        },
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: programsFormActions.FETCH_ERROR,
      });
    }
  },

  doAdd: (values) => async (dispatch) => {
    try {
      dispatch({
        type: programsFormActions.ADD_STARTED,
      });

      await ProgramsService.create(values);

      dispatch({
        type: programsFormActions.ADD_SUCCESS,
      });

      Message.success(i18n('program.doAddSuccess'));

      getHistory().push('/programs/');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: programsFormActions.ADD_ERROR,
      });
    }
  },

  doUpdate: (programId, data) => async (dispatch, getState) => {
    try {
      dispatch({
        type: programsFormActions.UPDATE_STARTED,
      });

      const res = await ProgramsService.updateProgram(programId, data);

      dispatch({
        type: programsFormActions.UPDATE_SUCCESS,
      });

      Message.success(i18n(res));

      getHistory().push('/program/' + programId);
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: programsFormActions.UPDATE_ERROR,
      });
    }
  },

  doDelete: (programId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: programsFormActions.UPDATE_STARTED,
      });

      const res = await ProgramsService.deleteProgram(programId);

      dispatch({
        type: programsFormActions.UPDATE_SUCCESS,
      });

      Message.success(i18n(res));

      getHistory().push('/programs');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: programsFormActions.UPDATE_ERROR,
      });
    }
  },

  doAddTechnology: (programId, competencyId) => async (dispatch) => {
    try {
      dispatch({
        type: programsFormActions.ADD_STARTED,
      });

      await ProgramsService.addTechnology(programId, competencyId);

      dispatch({
        type: programsFormActions.ADD_SUCCESS,
      });

      dispatch({
        type: programsFormActions.FETCH_STARTED,
      });

      const response = await ProgramsService.fetchPrograms();
      dispatch({
        type: programsFormActions.FETCH_SUCCESS,
        payload: {
          programs: response,
        },
      });

    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: programsFormActions.ADD_ERROR,
      });
    }
  },

  doDeleteTechnology: (competencyId) => async (dispatch) => {
    try {

      await ProgramsService.deleteTechnology(competencyId);

      dispatch({
        type: programsFormActions.ADD_SUCCESS,
      });

    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: programsFormActions.ADD_ERROR,
      });
    }
  },
};

export default programsFormActions;