import actions from 'src/modules/programs/form/programsFormActions';

const initialData = {
  loading: false,
  saveLoading: false,
  programs: [],
};

export default (state = initialData, { type, payload }) => {
  // if (type === actions.INIT_STARTED) {
  //   return {
  //     ...state,
  //     user: null,
  //     initLoading: true,
  //   };
  // }

  // if (type === actions.INIT_SUCCESS) {
  //   return {
  //     ...state,
  //     user: payload,
  //     initLoading: false,
  //   };
  // }

  // if (type === actions.INIT_ERROR) {
  //   return {
  //     ...state,
  //     user: null,
  //     initLoading: false,
  //   };
  // }

  if (type === actions.FETCH_STARTED) {
    return {
      ...state,
      loading: true,
    };
  }

  if (type === actions.FETCH_SUCCESS) {
    return {
      ...state,
      loading: false,
      programs: payload.programs,
    };
  }

  if (type === actions.FETCH_ERROR) {
    return {
      ...state,
      loading: false,
      programs: [],
    };
  }

  if (type === actions.ADD_STARTED) {
    return {
      ...state,
      saveLoading: true,
    };
  }

  if (type === actions.ADD_SUCCESS) {
    return {
      ...state,
      saveLoading: false,
    };
  }

  if (type === actions.ADD_ERROR) {
    return {
      ...state,
      saveLoading: false,
    };
  }

  // if (type === actions.UPDATE_STARTED) {
  //   return {
  //     ...state,
  //     saveLoading: true,
  //   };
  // }

  // if (type === actions.UPDATE_SUCCESS) {
  //   return {
  //     ...state,
  //     saveLoading: false,
  //   };
  // }

  // if (type === actions.UPDATE_ERROR) {
  //   return {
  //     ...state,
  //     saveLoading: false,
  //   };
  // }

  return state;
};