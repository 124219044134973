import { id } from 'date-fns/locale';
import authAxios from '../../modules/shared/axios/authAxios';

export default class placementsService {
    static async doStudentProcessCreate(data) {
        const response = await authAxios.post(
            `student/placement/`,
            data,
        );
        return response.data;
    }

    static async doOtherCreate(data) {
       const response = await authAxios.post(
            `student/placement/other`,
            data,
        );
        return response.data;
    }

    static async doCreateProccess(data) {
        const response = await authAxios.post(
            `student/placement/`,
            data,
        );
        return response.data;
    }

    static async doUpdateProccess(data) {
        const response = await authAxios.put(
            `student/placement/`,
            data,
        );
        return response.data;
    }

    static async destroy(ids) {
        const params = {
            ids,
        };

        const response = await authAxios.delete(
            `/placement`,
            {
                params,
            },
        );

        return response.data;
    }

    static async create(data) {

        const response = await authAxios.post(
            `/placement`,
            data,
        );

        return response.data;
    }

    static async find(id) {
        const response = await authAxios.get(
            `student/placement/${id}`,
        );
        return response.data;
    }

    static async fetchPlacements(studentId, filter?, orderBy?, limit=100, offset?) {
        const params = {
            companyId: ""
        }

        const response = await authAxios.get(
            `student/placement/student/${studentId}`,
            { params }
        );

        return response.data;
    }
    static async fetchPlacementsForCompany(companyId, filter, orderBy, limit, offset) {
        const params = {
            studentId: ""
        }

        const response = await authAxios.get(
            `student/placement/company/${companyId}`,
            { params }
        );

        return response.data;
    }

    static async fetchPlacementAutocomplete(query, limit) {
        const params = {
            query,
            limit,
        };

        const response = await authAxios.get(
            `student/placement/autocomplete`,
            {
                params,
            },
        );
        return response.data;
    }
    static async fetchAllPlacements(filter, orderBy, limit, offset) {

        const response = await authAxios.get(
            `student/placement/admin`,
        );

        return response.data;
    }
}
