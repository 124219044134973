import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';
import StudentsService from 'src/modules/students/studentsService';

const prefix = 'STUDENTS_VIEW';

const studentsViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: studentsViewActions.FIND_STARTED,
      });

      const students = await StudentsService.find(id);
      console.log(students)
      dispatch({
        type: studentsViewActions.FIND_SUCCESS,
        payload: students,
      });
    
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: studentsViewActions.FIND_ERROR,
      });

      getHistory().push('/students');
    }
  },
};

export default studentsViewActions;
