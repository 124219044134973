import actions from './placementsFormActions';

const initialData = {
    initLoading: false,
    saveLoading: false,
    otherLoading: false,
    placements: null,
};

const placementsFormReducers = (state = initialData, { type, payload }) => {
    if (type === actions.INIT_STARTED) {
        return {
            ...state,
            placements: null,
            initLoading: true,
        };
    }
    
    if (type === actions.INIT_SUCCESS) {
        return {
            ...state,
            placements: payload,
            initLoading: false,
        };
    }

    if (type === actions.INIT_ERROR) {
        return {
            ...state,
            placements: null,
            initLoading: false,
        };
    }
    
    if (type === actions.ADD_OTHER_STARTED) {
        return {
            ...state,
            otherLoading: true,
        };
    }

    if (type === actions.ADD_OTHER_SUCCESS) {
        return {
            ...state,
            otherLoading: false,
        };
    }

    if (type === actions.ADD_OTHER_ERROR) {
        return {
            ...state,
            otherLoading: false,
        };
    }

    if (type === actions.ADD_STARTED) {
        return {
            ...state,
            saveLoading: true,
        };
    }

    if (type === actions.ADD_SUCCESS) {
        return {
            ...state,
            saveLoading: false,
        };
    }

    if (type === actions.ADD_ERROR) {
        return {
            ...state,
            saveLoading: false,
        };
    }

    if (type === actions.UPDATE_STARTED) {
        return {
            ...state,
            saveLoading: true,
        };
    }

    if (type === actions.UPDATE_SUCCESS) {
        return {
            ...state,
            saveLoading: false,
        };
    }

    if (type === actions.UPDATE_ERROR) {
        return {
            ...state,
            saveLoading: false,
        };
    }

    return state;
};

export default placementsFormReducers;
