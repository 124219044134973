import Errors from 'src/modules/shared/error/errors'
import Message from 'src/view/shared/message'
import CohortService from 'src/modules/cohorts/cohortsService'
import { getHistory } from 'src/modules/store'
import { i18n } from 'src/i18n';

const prefix = 'COHORT_FORM';
// ACA PUEDE LLEGAR A HABER ERROR, en caso de no haberlo modificar en STUDENTS Y EN PROGRAM!!!!

const cohortsFormActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,
  FETCH_ONE_SUCCESS: `${prefix}_FETCH_ONE_SUCCESS`,
  FETCH_ONE_ERROR: `${prefix}_FETCH_ONE_ERROR`,

  ADD_STARTED: `${prefix}_ADD_STARTED`,
  ADD_SUCCESS: `${prefix}_ADD_SUCCESS`,
  ADD_ERROR: `${prefix}_ADD_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  DELETE_STARTED: `${prefix}_DELETE_STARTED`,
  DELETE_SUCCESS: `${prefix}_DELETE_SUCCESS`,
  DELETE_ERROR: `${prefix}_DELETE_ERROR`,

  doFetch: () => async (
    dispatch,
    getState,
  ) => {
    try {
      dispatch({
        type: cohortsFormActions.FETCH_STARTED,
      });
      const response = await CohortService.fetchCohorts();
      dispatch({
        type: cohortsFormActions.FETCH_SUCCESS,
        payload: { cohorts: response }
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: cohortsFormActions.FETCH_ERROR,
      });
    }
  },

  doFetchOne: (cohortId) => async (
    dispatch,
    getState,
  ) => {
    try {
      dispatch({
        type: cohortsFormActions.FETCH_STARTED,
      });
      const response = await CohortService.fetchOneCohort(cohortId);
      dispatch({
        type: cohortsFormActions.FETCH_ONE_SUCCESS,
        payload: { cohort: response }
      });
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: cohortsFormActions.FETCH_ONE_ERROR,
      });
    }
  },

  doAdd: (values, id) => async (dispatch) => {
    try {
      dispatch({
        type: cohortsFormActions.ADD_STARTED,
      });
      await CohortService.createCohort(values, id);
      dispatch({
        type: cohortsFormActions.ADD_SUCCESS
      });
      Message.success(i18n('cohorts.doAddSuccess'));
      getHistory().push('/cohorts/')
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: cohortsFormActions.ADD_ERROR
      });
    }
  },

  doUpdate: (values, id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: cohortsFormActions.UPDATE_STARTED
      });

      await CohortService.updateCohort(values, id);

      dispatch({
        type: cohortsFormActions.UPDATE_SUCCESS
      });

      Message.success(i18n('cohorts.doUpdateSuccess'));
      getHistory().push('/cohorts/')
    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: cohortsFormActions.UPDATE_ERROR,
      });
    }
  },

  doDelete: (id) => async (dispatch) => {
    try {
      dispatch({
        type: cohortsFormActions.DELETE_STARTED
      });

      await CohortService.deleteCohort(id);

      dispatch({
        type: cohortsFormActions.DELETE_SUCCESS
      });

      Message.success(i18n('cohorts.doDeleteSuccess'));
      getHistory().push('/cohorts/')

    } catch (error) {
      Errors.handle(error);
      dispatch({
        type: cohortsFormActions.DELETE_ERROR,
      });
    }
  }
}

export default cohortsFormActions;