import { createSelector } from "reselect";

const selectRaw = (state: any) => state.students.list;

const selectLoading = createSelector([selectRaw], (raw) => raw.loading);

const selectAccountAnalytics = createSelector(
  [selectRaw],
  (raw) => raw.accountAnalytics
);

const selectAnalytics = createSelector([selectRaw], (raw) => raw.analytics);

const selectRows = createSelector([selectRaw], (raw) => raw.rows);

const selectCohorts = createSelector([selectRaw], (raw) =>
  raw.cohorts.map(({ name, code }) => ({
    label: name,
    value: code,
  }))
);

const selectCompanies = createSelector([selectRaw], (raw) =>
  raw.companies.map(({ name }) => ({
    label: name,
    value: name,
  }))
);

const selectStudentById = createSelector([selectRaw], (raw) => raw.studentById);

const selectTechnologies = createSelector(
  [selectRaw],
  (raw) => raw.technologies || []
);

const selectCareer = createSelector([selectRaw], (raw) => raw.career);

const selectLocation = createSelector([selectRaw], (raw) => raw.location || []);

const selectRegions = createSelector([selectRaw], (raw) => raw.regions || []);

const selectPrograms = createSelector([selectRaw], (raw) => raw.programs);

const selectStudents = createSelector([selectRaw], (raw) => raw.students);

const selectCount = createSelector([selectRaw], (raw) => raw.count);
const selectRowsTier2 = createSelector([selectRaw], (raw) => raw.tier2);

const selectCountTier2 = createSelector([selectRaw], (raw) => raw.tier2count);
const selectHasRowsTier2 = createSelector(
  [selectCountTier2],
  (count) => count > 0
);
const selectNames = createSelector([selectRaw], (raw) => raw.names);
const selectCountries = createSelector(
  [selectRaw],
  (raw) => raw.countries || []
);
const selectHasRows = createSelector([selectCount], (count) => count > 0);
const selectSorter = createSelector([selectRaw], (raw) => raw.sorter || {});

const selectOrderBy = createSelector([selectRaw], (raw) => {
  const sorter = raw.sorter;

  if (!sorter) {
    return null;
  }

  if (!sorter.field) {
    return null;
  }

  let direction = sorter.order === "desc" ? "DESC" : "ASC";

  return `${sorter.field},${direction}`;
});

const selectFilter = createSelector([selectRaw], (raw) => {
  return raw.filter;
});

const selectRawFilter = createSelector([selectRaw], (raw) => {
  return raw.rawFilter;
});

const selectLimit = createSelector([selectRaw], (raw) => {
  const pagination = raw.pagination;
  return pagination.pageSize;
});

const selectOffset = createSelector([selectRaw], (raw) => {
  const pagination = raw.pagination;

  if (!pagination || !pagination.pageSize) {
    return 0;
  }

  const current = pagination.current || 1;

  return current - 1;
});

const selectPagination = createSelector(
  [selectRaw, selectCount],
  (raw, count) => {
    return {
      ...raw.pagination,
      total: count,
    };
  }
);

const selectSelectedKeys = createSelector([selectRaw], (raw) => {
  return raw.selectedKeys;
});

const selectSelectedRows = createSelector(
  [selectRaw, selectRows],
  (raw, rows) => {
    return rows.filter((row) => raw.selectedKeys.includes(row.id));
  }
);

const selectIsAllSelected = createSelector(
  [selectRows, selectSelectedKeys],
  (rows, selectedKeys) => {
    return rows.length === selectedKeys.length;
  }
);

const selectRowsCvs = createSelector([selectRaw], (raw) => {
  return raw.rowsCvs;
});

function organizeByType(arr) {
  return arr.reduce((acc, item) => {
      if (!acc[item.type]) {
          acc[item.type] = [];
      }
      acc[item.type].push(item);
      return acc;
  }, {});
}

const selectRowsCvsForm = createSelector([selectRaw], (raw) => {
  return organizeByType(raw.rowsCvs);
});

const selectLoadingCvs = createSelector([selectRaw], (raw) => {
  return raw.loadingCvs;
});

const studentsListSelectors = {
  selectRowsTier2,
  selectHasRowsTier2,
  selectLoading,
  selectRows,
  selectCount,
  selectOrderBy,
  selectLimit,
  selectFilter,
  selectOffset,
  selectPagination,
  selectSelectedKeys,
  selectSelectedRows,
  selectStudents,
  selectHasRows,
  selectRawFilter,
  selectIsAllSelected,
  selectSorter,
  selectNames,
  selectCountries,
  selectTechnologies,
  selectLocation,
  selectPrograms,
  selectCareer,
  selectStudentById,
  selectAccountAnalytics,
  selectAnalytics,
  selectCohorts,
  selectCompanies,
  selectRegions,
  selectRowsCvs,
  selectLoadingCvs,
  selectRowsCvsForm
};

export default studentsListSelectors;
