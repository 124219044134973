import list from 'src/modules/students/list/studentsListReducers';
import form from 'src/modules/students/form/studentsFormReducers';
import view from 'src/modules/students/view/studentsViewReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
});
