import authAxios from 'src/modules/shared/axios/authAxios';
import registerAxios from 'src/modules/shared/axios/registerAxios';
import { AuthToken } from 'src/modules/auth/authToken';


export default class AuthService {

  static async signUpStudent({ data, cohortCode }) {
    // const params = {
    //   cohortCode
    // }

    // const body = {
    //   data,
    // };
    console.log(data, cohortCode)

    const response = await registerAxios.post(`student/?cohortCode=${cohortCode}`,
      data
    );
    return response.data;
  }
  
  static async resetPassword(email){
    const response = await authAxios.put(`account/user/reset-password/${email}`);
    return  response.data;
  }

  static async updatePassword(updatePassword){
    const body = updatePassword
    const response = await authAxios.post('uaa/user/update-password/', body);
    return response.data;
  }

  static async signinWithEmailAndPassword(username, password) {

    let urlencoded = new URLSearchParams()

    urlencoded.append("grant_type", "password");
    urlencoded.append("username", username);
    urlencoded.append("password", password)

    const response = await authAxios.post('uaa/oauth/token', urlencoded);
    return response.data;
  }

  static async fetchMe() {
    const response = await authAxios.get('uaa/user/me');
    return response.data;
  }

  static async signout() {
   await AuthToken.set(null, true);
  }

  static async updateProfile(data) {
    const body = {
      data,
    };

    const response = await authAxios.put(
      '/auth/profile',
      body,
    );

    return response.data;
  }

  static async deletePhotoProfile(id) {

    const response = await authAxios.delete(`/student/${id}/profile-photo`, {
      responseType: "blob",
    });

    return response.data;
  }

  static async getPhotoProfile(id) {

    const response = await authAxios.get(`/student/${id}/profile-photo`, {
      responseType: "blob",
    });

    return URL.createObjectURL(response.data);
  }

  static async updatePhotoProfile(id, fileView) {

    const formData = new FormData();
    formData.append("imageFile", fileView || "");

    const response = await authAxios.put(
      `/student/${id}/profile-photo`,
      formData
    );

    return response.data;
  }
}
