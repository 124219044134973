import authAxios from 'src/modules/shared/axios/authAxios';

export default class MentorService {

  static async find(id) {
    const response = await authAxios.get(
        `student/program/mentor/${id}`
    );
    return response.data;
  }

  static async fetchMentors() {
    const response = await authAxios.get(
      `student/program/mentor/`
    );
    return response.data
  }

  static async addMentor(data){
    const response = await authAxios.post(
      `student/program/mentor/`,
      data
    );
    // return response.data
  }

  static async deleteMentor(id){
    const response = await authAxios.delete(
      `student/program/mentor/${id}`
    );
    return response.data
  }

  static async modifyMentor(id, data){
    const response = await authAxios.put(
      `student/program/mentor/${id}`,
      data
    );
    return response.data
  }
  
}