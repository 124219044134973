import authAxios from 'src/modules/shared/axios/authAxios';

export default class ProgramsService {
  static async fetchPrograms() {
    const response = await authAxios.get(
      `student/program/`,
    );
    return response.data
  }

  static async create(data) {
    const response = await authAxios.post(
      `student/program/`,
      data,
    );
    return response.data
  }

  static async updateProgram(programId, data) {
    const response = await authAxios.put(
      `student/program/${programId}`,
      data,
    );
    return response.data
  }

  static async deleteProgram(programId) {
    const response = await authAxios.delete(
      `student/program/${programId}`,
    );
    return response.data
  }

  static async addTechnology(programId, technologyId) {
    const params = {
      technologyId
    }

    const response = await authAxios.post(
      `student/program/${programId}/competency`, {},
      { params }
    );

    return response.data
  }

  static async deleteTechnology(competencyId) {

    const response = await authAxios.delete(
      `student/program/competency/${competencyId}`
    );

    return response.data
  }

}
