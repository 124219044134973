import actions from 'src/modules/cohorts/view/cohortsViewActions';

const initialData = {
  loading: false,
  cohort: {} as Object,
  cohortCalender: null,
};

export default (state = initialData, { type, payload }) => {
  if (type === actions.FIND_STARTED) {
    return {
      ...state,
      //cohort: payload,
      loading: true,
    };
  }

  if (type === actions.GET_COHORT_CALENDER){
    return {
      ...state,
      cohortCalender: payload,
    }
  }

  if (type === actions.FIND_SUCCESS) {
    console.log(payload)
    return {
      ...state,
      cohort: payload,
      loading: false,
    };
  }

  if (type === actions.FIND_ERROR) {
    return {
      ...state,
      cohort: {},
      loading: false,
    };
  }

  return state;
};
